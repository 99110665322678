import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import Catalogo from '../partils/Catalogo'
//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'


import MenuPainelAdmin from '../partils/AdminMenuPainel'


export default function AdminAgenda(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Loja virtual',
        mainstring:'nome',
        url:'/api/agenda/',
        url2:'',
        search:'/api/agenda/search/',
        searchCliente:'/api/agenda/search/cliente',
        //urlOptions1:'/api/produtocategoria',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    ////    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        id_cliente:'1', dia:'', hora:'', nome:'null', servico:'null', status:'1', datainicio:'', datafim:'',telefone:''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE

    
    const [data, setData] = useState([])
    const [date, setDate] = useState(new Date());
    const [dates, setDates] = useState(null);// range de datas para um servico
    const [dataocuped, setDataocuped] = useState([])
    const [dataCliente,setDataCliente]=useState([])
    const tenancylocal = localStorage.getItem('ClienteTenancy')
    //const occupiedDays = ['2024-10-16']; // Dias marcados como ocupados (dia 5 e 6)

    //const[dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            setData(resp.data)
            console.log(data)
            setDataocuped(resp.data)
        })
        axios.get('/api/cliente/').then(resp=>
            setDataCliente(resp.data)
        )
    }

    //PESQUISA UMA DATA EM ESPECIFICO
   
    

       
    //console.log(data)
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    
          
    const handleCopiarTexto = async () => {
        //setDatapx(dsewetdasdeasvrasf5w4fss);
        try {
          await navigator.clipboard.writeText(`https://autometricas.com.br/loja/${tenancylocal}`);
          alert('Link da sua loja copiado! Cole e acesse em um navegador de internet');
        } catch (error) {
          console.error('Erro ao copiar texto:', error);
        }
      };

    return(
        <>
        {openNew&&
        <div className="h100 w100 cell-tamanho row center celular posiabstop0">
                <div className='w100 h100  left0  column center z17 overflow' >
                <div className='w100 h100 posifixedinicio pcb50 fundo-opaco z5' onClick={()=>(setOpenNew(false) & setValues(initialValues))}>
                </div>
                <div className='h20 w35 b2 cont-circ3 borda1 column between z10 cell-tamanho90'>
                    <div className='w90 h20 column between'>
                        <h5 className='w90'>Agendar novos serviços</h5>
                      
                            
                            <label>Selecione o cliente</label>
                           
                         </div>
                         <div className='w90'>
                         <label>Selecione o serviço</label>
                         <select onChange={onChange} name='servico' value={values.servico}>
                            <option value={"Alinhamento"}>Alinhamento</option>
                            <option value={"Balanceamento"}>Balanceamento</option>
                            <option value={"Suspensão"}>Suspensão</option>
                            <option value={"Injeção Eletrônica"}>Injeção Eletrônica</option>
                            <option value={"Troca de Óleo"}>Troca de Óleo</option>
                            <option value={"Revisão Completa"}>Revisão Completa</option>
                            <option value={"Freios"}>Freios</option>
                            <option value={"Troca de Pneu"}>Troca de Pneu</option>
                            <option value={"Escapamento"}>Escapamento</option>
                            <option value={"Troca de Filtro"}>Troca de Filtro</option>
                            <option value={"Bateria"}>Bateria</option>
                            <option value={"Ar Condicionado"}>Manutenção do Ar Condicionado</option>
                            <option value={"Correia Dentada"}>Substituição de Correia Dentada</option>
                            <option value={"Radiador"}>Manutenção de Radiador</option>
                            <option value={"Embreagem"}>Embreagem</option>
                            <option value={"Diagnóstico Eletrônico"}>Diagnóstico Eletrônico</option>
                            <option value={"Avaliação"}>Avaliação</option>
                            <option value={"Outro"}>Outro</option>
                        </select>

                         </div>
                         <div className='w90 row between pcb20'>
                            <button className='w40 btn05' onClick={()=>setOpenNew(false)}>Cancelar</button>
                            <button className='w40'>Salvar</button>
                         </div>
                         
                    
                </div>
                
            </div>
            </div>
}
         <section className='h100 row between b31'>
            
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center celular">
                    
                    <div className="w100 row between cell-tamanho celular">
                        <div className="h100 w100 cont-circ3  column cell-tamanho cell-paddingcb10">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center'  autoComplete='off'>
                            <div className='btn10 b37' onClick={()=>(setOpenNew(true))&setValues(initialValues)}>Add</div>
                            <div className='w60'>
                                <input type="text" name="nome" onChange={onChange} placeholder='Pesquisar cliente agendado'/>
                            </div>
                            <div className='w20 b37'>
                                <input type="submit" value="Pesquisar" className='cell-text-smile08'/>
                            </div>
                        </form>
                        
                        <div className='w90 h20 row between  celular aligitensstart'>
                            <label className='w100 ma5 fs1p3em pcb10'>{config.pageName} Informações </label>
                            <div className='w100  b2  column cell-tamanho borda1 ma25b0'>
                                <div className='w100  column center b36 pcb10'>
                                    <div className='w100 column center '>
                                        <label className='fb c3'>Tutorial de uso</label>
                                        
                                    </div>
                                </div>
                                <div className='w90 h10 column'>
                                    <label className='w100 ma10b0'>Para adcionar produtos a sua loja você deve abrir o menu "produtos e serviços", clicar em editar produto e logo apos selecionar o status como "Listar na loja" ao fazer isso será permitido adcinar foto do produto</label>
                                    <label className='w100 ma10b0'>O Link para a sua loja é:</label>
                                    <label className='w100 ma10b0' >https://autometricas.com.br/loja/{tenancylocal}</label>
                                    <label className='w100 fs08em fb pp' onClick={()=>handleCopiarTexto()}>Clique aqui para Copiar o link da loja para disponibilizar aos clientes</label>
                                    <label className='w100 ma10b0'>Para editar as informações iniciais da loja selecione o menu "Dados empresa" e "Editar Dados"</label>
                                </div>
                            </div>

                            <div className='column start cell-tamanho top0 borda1 b2'>
                                <div className='w100  column center b36 pcb10'>
                                    <div className='w100 column center '>
                                        <label className='fb c3'>View Admin - Modo visualização</label>
                                        
                                    </div>
                                </div>
                                <Catalogo/>
                                
                            </div>
                           


                        </div>     
                    </div>
                </div>
            </div>
            
        </section>
      </>
    )
}

