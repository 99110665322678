import { Link, redirect } from "react-router-dom";

import ImgAnimationHeader from "../../acets/img_site/header-img.svg"
import ImgAnimationCarrinho from "../../acets/img_site/carrinnho.svg"
import ImgCellContato from "../../acets/img_site/imgrelogiocontato.svg"

import IconMenuPreco from"../../acets/img_site/precos.svg"
import IconMenuHome from"../../acets/img_site/home.svg"
import IconMenuVantagens from"../../acets/img_site/servicos.svg"
import IconMenuContato from"../../acets/img_site/contato.svg"
import IconMenuSobre from"../../acets/img_site/sobre.svg"
import IconMenuLogin from"../../acets/img_site/login.svg"

import IconPainelDemo from"../../acets/img_site/paineldemo.gif"
import IconEstrelas from"../../acets/img_site/estrelas.png"

import VideoAutoPlayer from "../../acets/img_site/videocarol.mp4"

import ImgIconCheck from "../../acets/img_site/checked.svg"
import ImgIconNocheck from "../../acets/img_site/nochecked.svg"
import Jota from"../../acets/img_site/jota.png"
//import ImgCell from "../../acets/img_site/cell.png"
import LogoCell from "../../acets/img_icon/logocellsite.svg"
import LogoPgPx from "../../acets/img_icon/hgdjhgjyfiytrdafsfr.png"
//import ImgWpp from "../../acets/img_site/wpp.jpg"
import ImgWpp from "../../acets/img_icon/wpp.png"
import ImgNewWpp from "../../acets/img_site/newwpp.svg"
import { useEffect, useState } from "react";
import axios from "axios";
import Contador from "../partils/ContatorRegressivo"
import { useNavigate } from "react-router-dom";


export default function PageIndex(){
const navigate = useNavigate();
const [terms,setTerms]=useState(true)
const [newlaster,setnewlaster]=useState(false)

const [cad01,setCad01]=useState(true)
const [cad02,setCad02]=useState(false)
const [cad03,setCad03]=useState(false)

const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
    nome: '',telefone:'',email:'',porte:'MEI'
}
const [values, setValues]=useState(initialValues) // INICIANDO UM STATE

useEffect(() => {
    const timeoutId = setTimeout(() => {
            setnewlaster(true)
    }, 20000); // Tempo em milissegundos para o timeout
    return () => clearTimeout(timeoutId);
  }, []);


function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
    const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
    setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
}
function onSubmit(ev){
        ev.preventDefault();
        //nome: '',telefone:'',email:'',porte:'MEI'
        //const inputIsChecked = document.getElementById('vehicle1');
        //console.log(inputIsChecked)
        if(values.nome,values.telefone===""){
            alert("ATENÇÃO. Preencha os campos de nome, telefone, aceite de email e porte obrigatoriamente")
        }
        else{
            axios.post('/api/leads',values).then(()=>{
                //setValues(initialValues);
                setCad02(true)
                setCad01(false)
                //alert("Dados enviados com sucesso, você receberá um email com as informações de login em breve!")
            })
        }
}

  /*
window.Notification.requestPermission(permission=>{
    if(permission==='granted'){
        new window.Notification('Habits',{
            body:'Textoh'
        })
    }
})
*/

function Countdown() {
  const [time, setTime] = useState(180); // 2 minutos em segundos

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(interval);
          return 0; // Para garantir que não fique negativo
        }
      });
    }, 1000);

    return () => clearInterval(interval); // Limpeza do intervalo

  }, []); // Executa apenas na montagem do componente

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return (
    <label className="fs2em fb">{formattedTime}</label>
  );
}
//---------------------------------------------------------------------------------
    return(
      <>
        <header className="w100 h97 bheader column center b2">
            {terms&&
            <div className="w100 h40 posifixedfim0 fundo-opaco z7 column center">
                <div className="column center b2 w80 cell-tamanho90 cont-circ8">
                    <h5>Aceitar termos e cookies?</h5>
                    <label className="w90 cell-tamanho90 cen fs08em ">Os
                    <Link to="" className="fb"> termos e cookies </Link>
                     são elementos importantes da política de privacidade na internet, pois garantem transparência e segurança no uso dos dados dos usuários</label>
                    
                    <div className="row center p10">
                        <button className="btn05 ml10">Recusar</button>
                        <button className="ml10" onClick={()=>setTerms(false)}>Aceitar</button>
                    </div>
                </div>
            </div>
            }
            
            <div className="w80 wmax1200 h70 b row center">
                <nav className="w100 h5 row between">
                    <ul className="w60 row between center h5 celular cell-none">
                        <a href="" className="cell-tamanho">
                            <li className="cell-tamanho cell-bg cell-margin5 home row center">
                            <img src={IconMenuHome} alt="icone de casa" className="mac0l8"></img>
                                <label className="c1 pp">
                                    Home
                                </label>
                            </li>
                        </a>
                        <a href="#vantagens" className="cell-tamanho">
                            <li className="cell-tamanho  cell-bg cell-margin5 row center">
                                <img src={IconMenuVantagens} alt="icone de engrenagens" className="mac0l8"></img>
                                <label  className="c1 pp">
                                    Serviços
                                </label>
                            </li>
                        </a>
                        <a href="#contato" className="cell-tamanho">
                            <li className="cell-tamanho  cell-bg cell-margin5 row center">
                                <img src={IconMenuContato} alt="icone de uma carta" className="mac0l8"></img>
                                <label className="c1 pp">
                                    Contato
                                </label>
                            </li>
                        </a>
                        
                        <a href="#sobre" className="cell-tamanho">
                            <li className="cell-tamanho  cell-bg cell-margin5 row center">
                            <img src={IconMenuSobre} alt="icone de lupa representando ajuda" className="mac0l8"></img>
                                <label className="c1 pp"> 
                                    Sobre
                                </label>
                            </li>
                        </a>
                        
                        
                        
                    </ul>
                    <Link to="/login" className="cell-tamanho  cont-circ8 p7 borda1 cell-none b2">
                        <li className="cell-tamanho  cell-bg cell-margin5 row center">
                            <img src={IconMenuLogin} alt="icone de um avatar/boneco ilustrativo"className="mac0l8"></img>
                            <label className="c1 pp"> 
                                Login
                            </label>
                        </li>
                    </Link>
                </nav>
                

                <div className="w40 h80 row start cell-tamanho">
                    <div className="w100 cell-tamanho h40 ">
                       <div className="desktop-none w100 column">
                            <Link to="/login" className="w100 h5 fb c1">Login</Link>
                       </div>
                       <h1 className="cell-text-title c1 ma10b0 fs3em w100">
                           A sua Plataforma para gerir vendas e serviços!
                        </h1>
                        <div className="ma10b0">
                            <label className="fs09em">Com dificuldades para gerir o estoque da sua loja? Com excesso de burocracia para emitir uma nota fiscal? E mais... sem um relatório diário sobre vendas no WhatsApp?</label>
                        </div>
                                                
                        <a href="#contato">
                            <button className="btn11 fs08em">
                                Temos a solução pra você!
                            </button>
                        </a>
                    </div>
                    
                </div>
                <div className="w60 h70 column center cell-none" data-aos="fade-left">
                  <img src={Jota} className="w90" alt="imagem de mecanico na oficina"/>
                </div>
            </div>
        </header>
        <section className="w100 h30 b27 column center pcb50" id="sobre"data-aos="zoom-in">
            <div className="w80 wmax1200 h30 row center celular ">
                <div className="w100 h50 row between cont-circ10" >
                    {//<img src={ImgCell} alt="cell" className="w20 cell-tamanho90 " ></img>
                    }
                    <img src={ImgAnimationHeader} alt="cell" className="w20 cell-tamanho90 desktop-none cell-none" ></img>
                    <div className="w70 cell-tamanho">
                    <h1 className="cell-text-title c1 ma10b0 fs2em">Auto Metricas</h1>
                    <h2 className="c1 fs1p3em w100 ">A melhor plataforma para gestão de negocios!</h2>
                        <div className="w100 row center ma10b0">
                            <div className="w100 cell-tamanho">
                               
                            </div>   
                            
                        </div>
                        

                        <label className="fs08em c1 w100 pcb10 ">PROMOÇÃO ENCERRA EM:</label>
                        <div className="w70 cell-tamanho">
                            <Contador/>
                        </div>
                        
                        
                    <h4 className="w100 cell-text-subtitle cell-margin10">Simplifique</h4>
                    <label className="cell-tamanho roboto fs1p3em cell-text-smile">
                    Mais que uma plataforma para gerenciar os serviços e vendas! Um time para lhe auxiliar com questões tecnologicas. migramos a sua planilha para o sistema GRÁTIS! fornecemos informações e relatorios direto para o seu WhatsApp diariamente! fornecemos um suporte direto por WhatsApp sempre que precisar!
                    </label>
                    <div className="w30 cell-tamanho ma10b0">
                        <a href="#contato">
                            <button className="btn11 fs08em">
                                Primeiro Mês R$: 43,99
                            </button>
                        </a>
                    </div> 
                    </div>
                    
                </div>
            </div>
        </section>

        <section className="w100 h80 column center pcb50 b2" id="vantagens">
        <div className="w80 wmax1200 pcb20 row center" data-aos="fade-right">
                <h4 className="w100 cell-text-subtitle cell-margin10">Transforme sua Gestão</h4>
                <div className="w60 column cell-tamanho ">
                        <h5 className="ma10b0 cell-content"></h5>
                        <img src={IconPainelDemo} className="w100 sombra" alt="imagem de mecanico na oficina"/>
                

                    
                </div>
                <div className="w40 cell-tamanho">
                    <div className="w100 h60 row end celular">
                        <div className="w60 h60 b37 cont-circ7 column between sombra cell-tamanho cell-margin10">
                            <h4 className="w100 cen pcb10 c3">Preço</h4>
                            <div className="row w90 cell-padding4">
                                <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                                <label className="fs08em c3">Link para sua oficina</label>
                            </div>
                            <div className="row w90">
                                <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                                <label className="fs08em c3">Cadastro de produtos e serviços</label>
                            </div>
                            <div className="row w90">
                                <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                                <label className="fs08em c3">Faturamento Diário no WhatsApp</label>
                            </div>
                            <div className="row w90">
                                <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                                <label className="fs08em c3">Orçamentos no WhatsApp</label>
                            </div>
                            <div className="row w90">
                                <img src={ImgIconCheck} alt="no" className="p7 cell-padding4"></img>
                                <label className="fs08em c3">Relatórios de desempenho</label>
                            </div>
                            <div className="row w90">
                                <img src={ImgIconCheck} alt="ok" className="p7 cell-padding4"></img>
                                <label className="fs08em c3">Relógio de ponto</label>
                            </div>
                            <div className="row w90">
                                <img src={ImgIconCheck} alt="no" className="p7 cell-padding4"></img>
                                <label className="fs08em c3">Agendamentos</label>
                            </div>
                            
                                <p className="fs06em c3 fb fs1p3em pcb10">Primeiro Mês R$:43,99</p>
                                <div className="row center w100 ">
                                    {/*
                                    <label className="fs06em sublinhado">R$:200,00 por :</label>
                                    <h4 className=" c3 fs08em">R$:179.90</h4>
                                    <label className="c3 fs08em">,00</label>
                                    <label className="fs06em c3">/Mês</label>
                                    */}
                                    <label className="fs06em c3 cen w90">Politica de descontos sob consulta com analista. Preços especiais para colaboradores que contribuam com sugestões de desenvolvimentos e feedbacks.</label>
                                    
                                </div>
                                
                                
                                <div className="w80 column fb fs08em ma10b0 pp c2">Termos e condições</div>

                            <a href="#contato" className="w90 pcb10"><button className="w100 h5 b2 c1 btn11">Começar</button></a>
                        </div>
                    </div>
                </div>
        </div>
        </section>
{/*
cnpj_cpf
status
email
senha
razao
nome_proprietario
endereco
whatsapp
instagram
tenancyId
obs_agenda
abre
fecha
capaimg
pix
token
color1
pix_estatico
createdAt
updatedAt*/
}
<section className="w100 h110 center column b27" id="planos">
  <div className="w80 wmax1200 h80 pcb50">
    <h4 className="w100 cell-text-subtitle pcb20">Depoimentos</h4>
    <div className="row between celular">
        <video autoPlay muted loop controls className="w30 h50 hauto video-container cell-tamanho">
            <source src={VideoAutoPlayer} type="video/mp4" />
            Seu navegador não suporta a reprodução de vídeos.
        </video>

        <div className="w30 h40 b2 column center sombra cont-circ8 cell-tamanho ma10b0">
            <div className="w90 ">
                <h4 className="pcb20 fs1p3em">Clévio Castro</h4>
                <label className="pcb50 c1 fs09em">Este sistema revolucionou a forma como gerenciamos nosso estoque e atendemos nossos clientes. Com ele, conseguimos controlar as vendas, registrar clientes e até enviar promoções personalizadas. A facilidade de uso e a automação de processos nos ajudaram a aumentar as vendas e fidelizar ainda mais os clientes. Recomendo para qualquer loja que quer profissionalizar a gestão!</label>
                <div className="w100 column center pcb20">
                    <img src={IconEstrelas} alt="Quatro estrelas"/>
                </div>
            </div>
        </div>
        <div className="w30 h40 b2 column center sombra cont-circ8 cell-tamanho">
            <div className="w90 ">
                <h4 className="pcb20 fs1p3em">Gabriel Filgueira</h4>
                <label className="pcb50 c1 fs09em">"Desde que começamos a usar este sistema na nossa oficina, a organização e a produtividade melhoraram significativamente. Agora conseguimos gerar orçamentos de forma rápida, acompanhar o status dos serviços e até enviar atualizações para os clientes via WhatsApp. Isso reduziu muito os atrasos e melhorou a satisfação dos nossos clientes. Sem dúvida, uma ferramenta essencial para qualquer oficina que quer crescer!"</label>
                <div className="w100 column center pcb20">
                    <img src={IconEstrelas} alt="Quatro estrelas"/>
                </div>
            </div>
        </div>
    </div>
   
   
  </div>
</section>


        <section className="w100 h50 center column  b2 pcb20" id="contato">
                <div className="w80 h40 cell-tamanho80  cont-circ8 column center b2 sombra wmax1200">
                    <form className="w50 pcb50 cell-tamanho80" onSubmit={onSubmit} autoComplete='off'>
                       {cad01&& 
                        <div className="">
                            <h4>CADASTRO 1/3</h4>
                            <div className="pcb10">
                                <strong className="w100 ">Primeira etapa</strong>
                            </div>
                            <label className="c5">Nome completo:</label>
                            <input type="text" name="nome" onChange={onChange} value={values.nome}></input>
                            <label className="c5">Numero de telefone com DDD:</label>
                            <input type="number" name="telefone" onChange={onChange} value={values.telefone}></input>
                            <label className="c5">Endereço de e-mail:</label>
                            <input type="email" name="email" onChange={onChange} value={values.email}></input>
                            <label className="c5">Seu cargo:</label>
                            <select name="porte" onChange={onChange} value={values.porte}>
                                <option value="Dono">Proprietario</option>
                                <option value="Auxiliar">Auxiliar</option>
                                <option value="Socio">Socio</option>
                                <option value="Funcionario">Funcionario</option>
                                <option value="Secretario">Secretario(a)</option>
                                
                            </select>
                            <div className="pcb10">
                                <input type="checkbox" id="vehicle1" className="pp"></input>
                                <label className="pp c5" htmlFor="vehicle1">Aceito os termos</label>
                            </div>
                            <button type="submit" value="Continuar com o cadastro" className="btn11 w100"> Continuar</button>
                        </div>
                        }

                       {cad02&&
                        <div>
                            <h4>CADASTRO 2/3 </h4>
                            <div className="pcb10">
                                <strong className="w100 ">Segunda etapa</strong>
                            </div>
                            <label>Pagamento</label>
                            <div className="row between pcb50 h20 celular">
                                <div className="w45 h50 column cell-tamanho">
                                    <label className="w100 cen pcb10 fb">Auto Métricas</label>
                                    <label className="fs08em ma10b0">Escaneie o QR Code com a câmera do seu celular ou copie a chave pix para realizar o pagamento.</label>
                                    <img src={LogoPgPx} alt="logopix" className="w100"/>
                                    <div className="row between w100 ma10b0">
                                        <button className="b18 c1 fs08em" onClick={() => navigator.clipboard.writeText("00020126960014BR.GOV.BCB.PIX0136ed4f29fc-2e99-4b7e-933b-1fd55827de090234Taxa de cadastro no sistema auto.m520400005303986540543.995802BR5925Geovane Filgueira de Alme6009SAO PAULO61080540900062250521hARCzXjELM99Q8J1a2kg163048879").then(() => alert("QR Code copiado!"))}>
                                            Copiar
                                        </button>
                                        <a href="https://nubank.com.br/pagar/1a2kg1/ZjQHgJnBfG" target="_blank" rel="noopener noreferrer">
                                            <button className="b18 c1 fs08em">Nubank</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="w45 h50 column around borda1 cell-tamanho">
                                    <label>Qr code pix expira em:</label>
                                    <Countdown/>
                                    <div className="column w90">
                                        <label className="fs09em w100 fb ma5">Valor:</label>
                                        <label className="fs08em w90 fb">R$:43,99</label>
                                        <label className="fs09em w100 fb ma5">Pagador:</label>
                                        <label className="fs08em w90">{values.nome}</label>
                                        <label className="fs09em w100 fb ma5">Beneficiario:</label>
                                        <label className="fs08em w90">Geovane Filgueira de Almeida</label>
                                        <label className="fs09em w100 fb ma5">Instituição:</label>
                                        <label className="fs08em w90">Nubank - NU pagamentos</label>
                                    </div>
                                </div>
                            </div>
                            <label className="fs09em">A taxa de cadastro R$:43,99 reais formaliza a contratação dos serviços de software de gestão para oficinas, garantindo a aquisição e liberação do sistema para uso. Ao realizar o pagamento, você concorda com as políticas e termos de uso.</label>
                            <button className="w100 btn11" onClick={()=>setCad02(false)&setCad03(true)}>Continuar</button>
                        </div>}
                        {cad03&&
                        <div>
                                <h4>CADASTRO 3/3 </h4>
                                <div className="pcb10">
                                    <strong className="w100 ">Terceira etapa</strong>
                                </div>
                                <label>Usuario e senha</label>
                                <div className="column arround pcb50 h10">
                                    <img src={ImgIconCheck} alt="checado" className="w40"></img>
                                    <label className="fs1p3em">Parabens!</label>
                                    <label className="pcb10">Seja bem vindo!</label>
                                    <label className="cen">Nosso time entrará em contato para iniciar o processo de onboarding no sistema, incluindo ambientação, treinamento e suporte. Um de nossos analistas fará uma ligação para entender melhor suas necessidades e adequar o sistema ao seu negócio.</label>
                                    <label>Segue tambem o nosso contato:</label>
                                    <div className="row pcb10">

                                        <a href="https://wa.me/5577999152909?text=Olá%2C%20fiz%20a%20minha%20inscrição%2C%20estou%20aguardando%20os%20dados%20de%20acesso.%20Att." className="b9 column center p10 cont-circ fb">WhatsApp</a>
                                        <label className="m5">Ou</label>
                                        <a href="mailto:contato.autometricas@gmail.com?subject=Inscrição&body=Olá%2C%20fiz%20a%20minha%20inscrição%2C%20estou%20aguardando%20os%20dados%20de%20acesso.%20Att." className="b9 column center p10 cont-circ fb">Email</a>
                                    </div>

                                    

                                  {  /*<label className="c5">Nome completo:</label>
                                    <input type="text" name="nome" onChange={onChange} value={values.nome}></input>
                                     <label className="c5">Nome completo:</label>
                                    <input type="text" name="nome" onChange={onChange} value={values.nome}></input>
                                     <label className="c5">Nome completo:</label>
                                    <input type="text" name="nome" onChange={onChange} value={values.nome}></input>
                                     <label className="c5">Nome completo:</label>
                                    <input type="text" name="nome" onChange={onChange} value={values.nome}></input>
                                    <button 
                                        className="w100" 
                                        onClick={() => { 
                                            navigate('/login'); 
                                            setCad01(true); 
                                            setCad03(false); 
                                            setValues(initialValues);
                                            alert("Cadastro realizado com sucesso! Agora faça o login e tenha acesso a todas as funcionalidades do sistema!")
                                        }}
                                        >
                                        Finalizar
                                    </button>
                                    */}
                                </div>
                            </div>
                            }
                    </form>
                
            </div>
        </section>
        
        <section className="w100 h40 row center">
            <div className="w80 row celular between pcb20 wmax1200 pcb50">
                <h4 className="pcb20">Perguntas frequentes</h4>
                    <div className="b2 w100 row center sombra-small pcb10 cont-circ8 m5">
                        <div className="w90 column">
                            <label className="w100 fb ma5">Posso usar no trabalho e em casa?</label>
                            <label className="w95">Sim! O sistema funciona 100% online, permitindo que você acesse de qualquer lugar, seja na oficina ou em casa.</label>
                        </div>
                    </div>

                    <div className="b2 w100 row center sombra-small pcb10 cont-circ8 m5">
                        <div className="w90 column">
                            <label className="w100 fb ma5">O sistema é facil de usar?</label>
                            <label className="w95">Sim! Criamos uma interface simples e intuitiva, para que qualquer pessoa possa usar sem complicações.</label>
                        </div>
                    </div>

                    <div className="b2 w100 row center sombra-small pcb10 cont-circ8 m5">
                        <div className="w90 column">
                            <label className="w100 fb ma5">Se o meu computador queimar perco tudo?</label>
                            <label className="w95">Não! Os dados estão armazenados na nuvem e com backup diario.</label>
                        </div>
                    </div>

                    <div className="b2 w100 row center sombra-small pcb10 cont-circ8 m5">
                        <div className="w90 column">
                            <label className="w100 fb ma5">O sistema funciona no celular?</label>
                            <label className="w95">Sim! Você pode acessar pelo computador, tablet ou celular e se quiser você pode instalar o nosso app direto no seu celular.</label>
                        </div>
                    </div>

                    <div className="b2 w100 row center sombra-small pcb10 cont-circ8 m5">
                        <div className="w90 column">
                            <label className="w100 fb ma5">Tenho que pagar mensalidade?</label>
                            <label className="w95">Oferecemos planos acessíveis que se ajusta a sua demanda!</label>
                        </div>
                    </div>

                    <div className="b2 w100 row center sombra-small pcb10 cont-circ8 m5">
                        <div className="w90 column">
                            <label className="w100 fb ma5">E se eu precisar de suporte?</label>
                            <label className="w95">Nosso suporte é rápido e eficiente. Você pode entrar em contato por WhatsApp sempre que precisar.</label>
                        </div>
                    </div>

                    <div className="b2 w100 row center sombra-small pcb10 cont-circ8 m5">
                        <div className="w90 column">
                            <label className="w100 fb ma5">Vale a pena investir nesse sistema?</label>
                            <label className="w95">Com certeza! Ele otimiza o tempo, reduz erros e aumenta a produtividade da sua oficina.</label>
                        </div>
                    </div>
            </div>
        </section>

        <section className="w100 h40 row center">
            <div className="w80 row celular between pcb20 wmax1200 pcb50">
                <h4 className="pcb20">Nossos Números</h4>
                <div className="column">
                    <h4>+10</h4>
                    <label>Empresas parceiras</label>
                </div>
                <div className="column">
                    <h4>+1000</h4>
                    <label>Registros de vendas</label>
                </div>
                <div className="column">
                    <h4>+3</h4>
                    <label>Anos no mercado</label>
                </div>
            </div>
        </section>

        <section className="w100 h40 b37 row center">
            <div className="w80 row celular between pcb20 wmax1200">
                <div className="w30 cell-tamanho">
                    <label className="fs1p3em c3">Redes Sociais</label>
                    <p className="c3 pcb10">Instagram</p>
                    <p className="c2 ">@autometricas</p>
                    <p className="c3 pcb10">You Tube</p>
                    <p className="c2 ">@autometricas</p>
                </div>
                <div className="w30 cell-tamanho">
                    <label className="fs1p3em c3 pcb10">Sobre</label>
                    <p className="fs08em c2 pcb10">Desenvolvida em 2023, a plataforma de gestão da Autométricas nasceu da necessidade de aprimorar a administração da oficina da família. Desde então, novas oficinas começaram a utilizar a plataforma e a sugerir melhorias. Em 2025, a solução expandiu seu alcance, sendo adotada por empresas de outros segmentos, tornando-se ainda mais versátil para o mercado.</p>
                </div>
                <div className="w30 cell-tamanho">
                   
                    <p className="c3 pcb20 cen">Mobi Apps</p>
                </div>
            </div>
        </section>

        <div className="posifixedfim20 rigth20 h10 w10 row end cell-tamanho-metade30">
            <a href="https://api.whatsapp.com/send?phone=5577999152909&text=Olá, quero saber mais sobre o sistema de oficinas ;D" className="w100 row end"><img src={ImgWpp} alt="ok" className="w100 cell-padding4"></img></a>
        </div>
        <footer className="w100 h5 b1 row center">
            <label className="c6 cen">2023 Copyright - todos os direitos reservados</label>
        </footer>
      </>
    )
}

/*

<!-- Google tag (gtag.js) --><script async src="https://www.googletagmanager.com/gtag/js?id=G-3YTVCNJ4N1"></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-3YTVCNJ4N1');</script>


*/