import axios from "axios";
import { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import AdminItensFuncionario from '../partils/AdminItensListaFuncionario'

import React, { useRef } from 'react';
import { toPng } from 'html-to-image';

import TenancyColor from "../midllers/TenancyColor";
import { useNavigate } from 'react-router-dom';

import ChartPedidosMes from'../partils/ChartPedidosMes'
import ChartFormaPG from'../partils/ChartFormaPG'
import ChartProdutosMaisVendidos from '../partils/ChartProdutosMaisVendidos'
import ChartCampanhaInstagram from '../partils/ChartCampanhaInstagram'
import ChartReceitaMes from'../partils/ChartReceitaMes'

import ImgMoreData from'../../acets/img_icon/moredatadash.svg'

import AdminHeader from "../partils/AdminHeader";

export default function Painel(){
   const navigate = useNavigate();
   const tlocal = localStorage.getItem('keyItemNameg')
   axios.defaults.headers.common['token'] = tlocal;

   const initialvalues ={totalFaturamento:0,valor_baixa:0,countOrcamento:'0',produto:'0',servico:'0',totalpivoorcaprod:'0',totalcusto:'0',totalclientefornecedoroficina:'0',repasserealizado:'0'}

   const [produto,setProduto]=useState(0)
   const [orcamento,setOrcamento]=useState(0)
   const [feedback,setFeedback]=useState(0)
   const [faturamento,setFaturamento]=useState(0)
   const [data,setData]=useState()
   const [checkout,setCheckout]=useState([])
   const [fatTotal,setFatTotal]=useState(0)
   const [values,setValues]=useState({initialvalues})
   const [showfilters, setShowfilters]=useState(false)
   const [showDataNunber,setShowDataNunber]=useState(false)
   const [opemrelatorio,setOpemrelatoeio]=useState(false)

   const [dataCliente,setDataCliente]=useState([])
   const [dataProdutos,setDataProdutos]=useState([])

   const [dataformapg,setDataformapg]=useState([])
   const [datadevedor,setDatadevedor]=useState([])

   // Obter a data de hoje no formato 'YYYY-MM-DD'
   const today = new Date();
   const year = today.getFullYear();
   const month = String(today.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
   const day = String(today.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
   const formattedToday = `${year}-${month}-${Number(day) + 1}`;

   
   const [intervalfinalDate, setIntervalfinalDate] = useState(formattedToday);
   const [intervalinitialDate,setIntervalinitialDate]=useState('2024-02-01')
   const[opemprodutosucess,setOpemprodutosucess]=useState(true)
   const divRef = useRef();//converter div para imagem

   const tenancylocal = localStorage.getItem('ClienteTenancy')

   useEffect(()=>{
      rec();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
      function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES 
         axios.get('/api/empresa/tenancy/one/' + tenancylocal)
            .then(resp => {
              const invalid = {ERRO: 'token invalido 3'};
                if (JSON.stringify(resp.data[0]) === JSON.stringify(invalid)) {
                  navigate('/login');
                  window.location.reload(true);
                }
                else{
                  setData(resp.data);
                }
            })
            .catch(error => {
              console.log(error);
              return <div>{error.message || error.toString()}</div>; // renderiza a mensagem de erro como uma string
            })
            .catch(err => console.log(err));

            axios.get(`/api/dashboard/orcamento/conttotal/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data.total)
                setValues(prevValues => ({
                    ...prevValues,
                    totalFaturamento: resp.data.total
                }));
            })
            axios.get(`/api/dashboard/orcamento/cont/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    countOrcamento: resp.data
                }));
            })
            axios.get(`/api/dashboard/pivo/baixaorcamento/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data.valor_baixa)
                setValues(prevValues => ({
                    ...prevValues,
                    valor_baixa: resp.data.valor_baixa
                }));
            })
            
            axios.get(`/api/dashboard/pivo/baixaorcamentotipo/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
               setDataformapg(resp.data)
            })
            axios.get(`/api/dashboard/produto/count/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    produto: resp.data
                }));
            })
            axios.get(`/api/dashboard/servico/count/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    servico: resp.data
                }));
            })
            axios.get(`/api/dashboard/pivoorcaprod/counttotal/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data.preco)
                setValues(prevValues => ({
                    ...prevValues,
                    totalpivoorcaprod: resp.data.preco
                }));
            })
            //oficina
            axios.get(`/api/dashboard/pivoorcaprod/counttotal/oficina/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    totalclientefornecedoroficina: resp.data.preco
                }));
            })
            


            axios.get(`/api/dashboard/custo/conttotal/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp => {
                setValues(prevValues => ({
                    ...prevValues,
                    totalcusto: resp.data.valor
                }));
            })
           {/* axios.get(`/api/clientefornecedor/3`)
            .then(resp => {
               //console.log(resp.data)
                setValues(prevValues => ({
                    ...prevValues,
                    totalclientefornecedoroficina: resp.data[0].saldo
                }));
            })
         */}
            axios.get(`/api/orcamento/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp =>{
               //console.log(resp.data)
               setOrcamento(resp.data)
            }) 
            axios.get(`/api/dashboard/pivo/baixaclientefornecedor/${intervalinitialDate}/${intervalfinalDate}`)
            .then(resp =>{
               //console.log(resp.data.valor_baixa)
               setValues(prevValues => ({
                  ...prevValues,
                  repasserealizado: resp.data.valor_baixa
              }));
            }) 
            axios.get(`/api/dashboard/orcamento/faltantes`)
            .then(resp =>{
               //console.log(resp.data)
               setDatadevedor(resp.data)
            })

            axios.get('/api/clientefuncionario/').then(resp=>{
               setDataCliente(resp.data)
               console.log(dataCliente)
            })
     }
    //console.log(dataCliente)
    const handleDownloadImage = () => {
            if (divRef.current === null) {
              return;
            }
        
            toPng(divRef.current)
              .then((dataUrl) => {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'relatorio.png'; // Nome do arquivo
                link.click();
              })
              .catch((err) => {
                console.log(err);
              });
          };


    const FuncionariosSevicos = dataCliente.map(repsValues => (
      <div className="w100 h5 linham2 row between">
         <label>{repsValues.cliente.nome}</label>

         <AdminItensFuncionario id={repsValues.id_clientefuncionario} dtini={intervalinitialDate} dtfim={intervalfinalDate}/>
        
      </div>

    ));
    
    const totalGanho = fatTotal&&fatTotal.reduce((acc, item) => {
     return acc + item.produto.preco_final;
    }, 0);
  
    const valorTotal = values.totalFaturamento;
    const valorParcial = values.valor_baixa-(values.totalFaturamento-values.totalclientefornecedoroficina)-values.totalcusto;
    
    const porcentagem = (valorParcial / valorTotal) * 100;
    
    //console.log(datadevedor)
    
   const rendermapdevedores = datadevedor.map(resp=>
      <div className="w90 row between pcb10 linha" key={resp.id_orcamento_venda}>
         <label className="w40">{resp.cliente.nome}</label>
         <div className="fb w25">
            <label className="fs06em">R$:</label>
            <label className="fb">{resp.valor_falta}</label>
         </div>
         <label className="fs09em fb w15 cell-none">{resp.createdAt.slice(0,10)}</label>
         <div
            className="w20 b37 fs09em c3 pcb7 pp cont-circ3 column center"
            onClick={() => {
               if (resp.cliente.telefone) {
                  const message = `Olá, percebemos que o orçamento de código ${resp.id_orcamento_venda} está com o valor de R$: ${resp.valor_falta} em aberto. Realize o pagamento e volte a ficar adimplente. para ter acesso ao orçamento digite "orçamento" na conversa do WhatsApp.     Visite o nosso site https://www.autometricas.com.br`;
                  const url = `https://wa.me/${resp.cliente.telefone}?text=${encodeURIComponent(message)}`;
                  window.open(url, '_blank');
               } else {
                  alert('Número de telefone não disponível.');
               }
            }}
            >
            Cobrar
         </div>


      </div>
   )

   

   function Dashboard(){
         return(
            <div className="w100 row between b2 cont-circ8 ma10b0 borda1"> 
                        <div className="h10  w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Quantidade de orçamentos na data">
                        <label className="ma10b0 fb c1">Orçamentos</label>
                        {/*<label className="fs06em cen c1">Quantidade na data </label>*/}
                        <label className=" fb h5 column center c4">{values.countOrcamento}</label>
                        </div>

                     

                        <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Tudo o dinheiro que entrou na empresa">
                        <label className=" ma10b0 fb c1">Faturamento</label>
                        {/*<label className="fs06em cen c1">Tudo o que entrou</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className={` fb h5 column center c4" ${values.totalFaturamento == values.totalpivoorcaprod ? 'c4' : 'c9 b1'}`}> {values.totalFaturamento }</label>
                           
                        </div>
                       {values.totalFaturamento != values.totalpivoorcaprod&&<label className="fb fs06em">{values.totalpivoorcaprod} pro.adds</label>}
                        
                        
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Baixas nos orcamento, soma dos recebidos">
                        <label className=" ma10b0 fb c1" >Recebido</label>
                        {/*<label className="fs06em cen c1">Baixas nos orcamentos</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className=" fb h5 column center c4">{values.valor_baixa}</label>
                        </div>
                     </div>
                    
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Soma do que ficou fiado nos orçamentos">
                        <label className=" ma10b0 c1 fb">A receber</label>
                        {/*<label className="fs06em cen c1">Fiados</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className=" fb h5 column center c4">{values.totalFaturamento-values.valor_baixa}</label>
                        </div>
                     </div>
                     {!showDataNunber&&
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5 pp" 
                     onClick={()=>setShowDataNunber(!showDataNunber)}
                        >
                        <label className=" ma10b0 fb c1" >Mais dados</label>
                        <img src={ImgMoreData} alt="Mais dados"/>
                     </div>
                     }
                     {showDataNunber&&
                     <>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Custos com despesas como aluguel, salarios, conta de luz, agua internet etc...">
                        <label className=" ma10b0 c1 fb">Custos</label>
                        {/*<label className="fs06em cen c1">Aluguel + salários</label>*/}
                        <div className="row">
                        <label className="fs06em">R$: </label>
                           <label className=" fb  h5 column center c4">{values.totalcusto}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Soma de todos os produtos presentes nos orçamentos em que o fornecedor não é a oficina, sendo fornecedores externos.">
                        <label className="ma10b0 c1 fb">Fornecedor</label>
                        {/*<label className="fs06em cen c1">Deve repass Fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="h5 fb column center c4">{values.totalFaturamento-values.totalclientefornecedoroficina}</label>
                        </div>
                     </div>
                     

                    
   {/*
                     <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5" >
                        <label className=" ma10b0 " >Serviços</label>
                        <label className="fs06em">Tipos de Serviços</label>
                        <label className=" fb h10 column center">{values.servico}</label>
                     </div>
   */}
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Mão de obra Total, incluindo os recebidos e os a receber">
                        <label className=" ma10b0 cen c1 fb">Serviços</label>
                        {/*<label className="fs06em cen c1">Mão de obra Total, recebido e a receber</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="fb h5 column center c4">{values.totalclientefornecedoroficina}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Representa o calculo: Faturamento - custos - fornecedores. sem levar em conta se foi recebido ou não.">
                        <label className=" ma10b0 c1 fb">Lucro Bruto</label>
                        {/*<label className="fs06em cen c1">Faturamento - custos - fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="fb h5 column center c4">{values.totalFaturamento-values.totalcusto-(values.totalFaturamento-values.totalclientefornecedoroficina)}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Representa o calculo: Faturamento - fornecedores. calcula o que entrou menos o que pertence aos fornecedores.">
                        <label className=" ma10b0 cen c1 fb">Lucro recebido</label>
                        {/*<label className="fs06em cen c1">Recebido - fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="fb h5 column center c4">{values.valor_baixa-(values.totalFaturamento-values.totalclientefornecedoroficina)}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Representa o calculo: Recebido - fornecedores - custos. sendo o que sobra para a retirada do pro labore ou o dinheiro para investir na oficina.">
                        <label className=" ma10b0 cen c1 fb">Lucro L. rcb</label>
                        {/*<label className="fs06em cen c1">Recebido - fornecedores - custos</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="h5 column center c4 fb">{values.valor_baixa-(values.totalFaturamento-values.totalclientefornecedoroficina)-values.totalcusto}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Transferencias realizadas aos fornecedores"> 
                        <label className=" ma10b0 cen c1 fb">Repasse Rlzdo.</label>
                        {/*<label className="fs06em cen c1">Transferencia ao fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="h5 column center c4 fb">{values.repasserealizado}</label>
                        </div>
                     </div>
                     <div className="h10 w15 cont-circ3  column start cell-tamanho-metade30 cell-margin5" title="Transferencias pendentes aos fornecedores">
                        <label className=" ma10b0 cen c1 fb">Repasse Pend.</label>
                        {/*<label className="fs06em cen c1">Pagamentos pendentes aos fornecedores</label>*/}
                        <div className="row">
                           <label className="fs06em">R$: </label>
                           <label className="h5 column center c4 fb">{(values.totalFaturamento-values.totalclientefornecedoroficina)-values.repasserealizado}</label>
                        </div>
                     </div>
                     {/*
                     <div className="h10 w15 b2 cont-circ3 sombra column start cell-tamanho-metade30 cell-margin5">
                        <label className=" ma10b0">Produtos</label>
                        <label className="fs06em">Produtos em estoque</label>
                        <label className=" fb h10 column center">{values.produto}</label>
                        </div>*/}
                     </>}
                     <div 
                        className="w100 h5 column center"
                        onClick={()=>setShowDataNunber(!showDataNunber)}>
                        {showDataNunber?
                        <label className="c1 fb fs08em pp">Minimizar dados</label>:
                        <label className="c1 fb fs08em pp">Mostrar mais dados</label>
                        }
                     </div>
                    </div>
         )
      }
      const finalprodutos = dataProdutos.map(respstring=>
         <div className="row between linha" key={respstring.id_pivo_orcamento_produto}>
            <label className="fs09em w15">{new Date(respstring.createdAt).toLocaleDateString("pt-BR")}</label>
            <label className="fs09em w5">{respstring.quantidade}</label>
            <label className="fs09em w60">{respstring.produto_servico.descricao}</label>
            <label  style={{
                    background: `${
                     respstring.orcamento.status ===1
                        ? "#68A652"
                        : respstring.orcamento.status ===2
                        ? "#FFEC87"
                        : respstring.orcamento.status ===3
                        ? "#B1FF95"
                        : respstring.orcamento.status ===4
                        ? "#FFA487"
                        : "transparent"
                    }`,
                    width:'10%',
                    fontSize:'0.8em',
                    textAlign:'center'
                }}>{
                    
                  respstring.orcamento.status === 1
                ? "Em Serv."
                : respstring.orcamento.status === 2
                ? "Pg Pend"
                : respstring.orcamento.status === 3
                ? "Pago"
                : respstring.orcamento.status === 4
                ? "Cancelado"
                : null
                }</label>
              
            <label className="fs09em w10">R$: {respstring.preco*respstring.quantidade}</label>
         </div>
      )
      function listarprodutos(){
         axios.get(`/api/dashboard/pivoorcaprod/contprodutosvendidos/${intervalinitialDate}/${intervalfinalDate}`).then(resp=>{
            setDataProdutos(resp.data)
            console.log(resp.data)
            setOpemprodutosucess(false)
          })
      }
      const totalProdutos = dataProdutos.reduce((total, item) => total + item.quantidade * item.preco, 0);
      const totalRecebido = dataProdutos
    .filter(item => item.orcamento.status === 3)
    .reduce((total, item) => total + item.quantidade * item.preco, 0);
     //style={{backgroundColor: TenancyColor().backgroundColor}}
    return(<>
      {opemrelatorio&&           
         <div className='w100 h700 posiabstop column center z17 top0' >
             <div className='w100 h700 posiabstop fundo-opaco z5 pcb10 top0' onClick={()=>(setOpemrelatoeio(false))}>
             </div>
             <div className='h20 w70 b2 z5 cont-circ3 sombra column center z10 cell-tamanho90 pcb10 ' ref={divRef}>
                 <div className='w65 h20'>
                     <div className='w100 cen'>
                        <label>{new Date().toLocaleDateString("pt-BR")}</label>
                     </div>
                     <h5 className="w100 cen fs2em pcb50">{tenancylocal}</h5>
                     <h5 className="fb pcb50">
                        Relatório Financeiro - {"De: " + new Date(intervalinitialDate).toLocaleDateString("pt-BR") + 
                        " até " + new Date(intervalfinalDate).toLocaleDateString("pt-BR")}
                     </h5>
                     <h5 className="fb">Introdução</h5>
                     <label>
                     Este relatório apresenta um balanço financeiro da oficina referente à data supracitada. Com uma abordagem técnica e objetiva, ele destaca o faturamento,
                     os custos operacionais e o lucro líquido, proporcionando uma visão clara do
                     desempenho financeiro do período. Essas informações auxiliam na tomada de decisões
                     </label>
                     <h5 className="fb">Desempenho Financeiro</h5>
                     <label>
                     Durante o período analisado, foram realizados <strong>{values.countOrcamento} </strong>orçamentos, resultando em um
                     faturamento total de R$:<strong>{values.totalFaturamento}</strong>. Deste valor, R$:<strong>{values.valor_baixa}</strong> já foram recebidos, enquanto R$:
                     <strong> {values.totalFaturamento-values.valor_baixa}</strong> ainda estão pendentes.
                     </label>
                     <h5 className="fb">Custos e Lucro</h5>
                     <label>
                     Os custos operacionais somaram R$:<strong>{values.totalcusto}</strong>, enquanto as despesas com peças de
                     fornecedores chegaram a R$:  <strong>{values.totalFaturamento-values.totalclientefornecedoroficina}</strong>. Os produtos e serviços prestados pela oficina geraram R$:
                     <strong>{values.totalclientefornecedoroficina}</strong>. Com isso, o lucro bruto da semana foi de R$:<strong>{values.totalFaturamento-values.totalcusto-(values.totalFaturamento-values.totalclientefornecedoroficina)}</strong>, porém, considerando os
                     valores já recebidos, o lucro líquido disponível ficou em R$: <strong>{values.valor_baixa-(values.totalFaturamento-values.totalclientefornecedoroficina)-values.totalcusto}</strong>.
                     </label>
                     <h5 className="fb">Repasse para Fornecedores</h5>
                     <label>
                        Até o momento, {values.repasserealizado ? (
                           <>foi realizado o total de R$ {values.repasserealizado}</>
                        ) : (
                           <>nenhum repasse foi realizado às autopeças,</>
                        )}{" "}
                        mas há um saldo pendente de R${" "}
                        <strong>
                           {values.totalFaturamento - values.totalclientefornecedoroficina - values.repasserealizado}
                        </strong>{" "}
                        a ser quitado.
                     </label>

                     <h5 className="fb">Conclusão</h5>
                     <label>
                     Os números demonstram um bom volume de serviços e um faturamento consistente,
                     mas a alta pendência financeira exige atenção para garantir o equilíbrio do fluxo de
                     caixa. A regularização dos repasses ajudará a manter boas relações com fornecedores e
                     a sustentabilidade da oficina.Esse relatório possui um dashboard focado em peças e serviços
                     e no fim da página uma tabela focada apenas em serviços.
                     </label>
                     <Dashboard/>
                     <div className="w100 row between pcb50">
                        <h5 className="fb">Mão de obra de cada funcionário:</h5>
                     </div>
                     <label>Para um funcionário que recebe R$ 2000/mês, considerando encargos e uma margem de lucro de 20%, ele precisa faturar no minimo R$ 4250/mês para ser rentável. Referência: Cálculo baseado em encargos trabalhistas e margem de lucro empresarial - Associação brasileira de pequenas e médias empresas.</label>
                     <div className="w100 pcb50">
                        {FuncionariosSevicos}
                     </div>
                     



                     <h5 className="fb">Produtos vendidos da oficina:</h5>
                     <div className="w100">
                        <div className="pcb10">
                           <label className="w100">Listagem somente de Produtos da oficina vendidos no periodo, desconsiderando o custo de cada produto. desta forma o total não representa o lucro. e sim o faturamento apenas.</label>
                        </div>
                        
                        {opemprodutosucess && <button onClick={()=>listarprodutos()}>Listar produtos</button>}
                        {finalprodutos}
                        <div className='pcb10 w100'> 
                           <label className='fb'>Balanço final da venda de peças da oficina:</label>
                        </div>
                        <h6 className='w100'>Total R$: {totalProdutos}</h6>
                        <h6 className='w100'>Recebido R$: {totalRecebido}</h6>
                        <h6 className='w100'>A receber:{totalProdutos-totalRecebido}</h6>
                     </div>
                    

                     <div className="pcb50 w100 column">
                        <h5 className="fb">Considerações finais</h5>
                        <label className="cen">
                        O presente relatório tem o objetivo de apoiar decisões estratégicas da empresa. Ele deve ser utilizado como um auxílio na análise de desempenho.
                        </label>
                     </div>
                     
                     <div className="pcb50 w100 column">
                        <label className="fb cen">Auto Métricas™</label>
                     </div>


                         
                         
                         
                     <div className='w100 row between'>
                         <label className='w40'>Sem mais opções</label>
                         <div className="row w60 between">
                           <button onClick={handleDownloadImage}>imprimir</button>
                           <button onClick={()=>(setOpemrelatoeio(false) )} className="w40 ma10b0 btn05">Cancelar</button>
                         </div>
                         
                     </div>
                     
                 </div>
             </div>
         </div>
     }
      <section className='h100 row between b31'>
         <AdminHeader props='Dashboard'/>
         <MenuPainelAdmin/>
         <div className="h20 w80  row center celular cell-tamanho ">
          
          <div className="w90 row between cell-tamanho90">
                     <div className="w100 fim pp p5" onClick={()=>setShowfilters(!showfilters)}>
                        <label 
                           className="c1 fs08em b2 pp">
                           {showfilters?'Fechar Filtros':'Abrir Filtros'}
                        </label>
                     </div>
                     

                     {showfilters&&
                     <div className="w100 b2 c3 b2 borda1 flex row center ma10b0">
                        <label className="w95 ma5 ">Selecione o intervalo de datas para obter os dados</label> 
                        <div className="w95 row between">
                           <div className="w33">
                              <label className="fs08em">Início</label>
                              <input 
                                 type="date"
                                 onChange={(e) => setIntervalinitialDate(e.target.value)}
                              />
                           </div>
                           <div className="w33">
                              <label className="fs08em">Fim</label>
                              <input 
                                 type="date"
                                 onChange={(e) => setIntervalfinalDate(e.target.value)}
                              />
                           </div>
                           <div className="w25">
                              <label>Ação</label>
                              <button className="w95" onClick={()=>rec()}>Ir</button>
                           </div>
                           <div className="fs08em ma5">
                              {"De: "+intervalinitialDate + " até " + intervalfinalDate }
                              <label className="fb fs09em pp" onClick={()=>setOpemrelatoeio(!opemrelatorio)}> Gerar Relatorio {opemrelatorio}- </label>
                              <label className="fb b3 fs09em cont-circ8">Novo</label> 
                           </div>
                        </div>
                        
                     </div>
                     }

                  
                     
                     {//import aqui
                     <Dashboard />
                     }
                     
                    <div className="h10 w100 cont-circ3 b2  ma10b0 column center borda1">
                        <h5 className="w90">Margem</h5>
                        <div className="w90 h5 cont-circ7 borda1 ma10b0">
                        <div className="b28 h5 column center " style={{ width: `${Math.min(porcentagem, 100).toFixed(2)}%` }}>
                        <label className="c1 fb">{porcentagem.toFixed(2)}%</label>
                           </div>
                        </div>
                     </div>
                    
                    <div className="h20 w100 b2 cont-circ3 b2 ma10b0 column center borda1">
                     {orcamento?
                        <ChartPedidosMes pedidos={orcamento}/>:
                        <label>NaN</label>
                     } 
                    </div>
                    
                    {/*
                    <div className="h20 w100 b2 cont-circ3 sombra ma25b0 column center">
                    <label className="cen">Receita diaria por Funcionario - em desenvolvimento</label>
                    {orcamento==="gg"&&
                        <ChartProdutosMaisVendidos/>
                     } 
                    </div>
                    */}
                    
                    <div className="h10 w100 b2 cont-circ3 b2  ma10b0 column center borda1">
                    
                     {orcamento&&
                        <ChartFormaPG pg={dataformapg}/>
                     } 
                     
                    </div>
                    {/*
                    <div className="h20 w100 b2 cont-circ3 sombra ma25b0 column center">
                    <label className="cen">Historico de Lucro liquido por mês</label>
                     {orcamento==="gg"&&
                        <ChartCampanhaInstagram/>
                     } 
                    </div>
                     */}
                    <div className="h20 w100 b2 cont-circ3 b2 column center mac0b10vh borda1">
                     <h5 className="w90 ">Maiores Devedores</h5>
                     {rendermapdevedores}
                    </div>
                </div>
                
               

            </div>
           
      </section>
      </>)
}
//67982113560 itau
