import { Link } from "react-router-dom";

import IconHome from "../../acets/img_icon/home.svg"
import IconCar from "../../acets/img_icon/carrinho.svg"
import IconUser from "../../acets/img_icon/user.svg"
import IconTicket from "../../acets/img_icon/ticket.svg"
import TenancyColor from "../midllers/TenancyColor"
//import ButtonTocarr from "../partils/BottomToCar"

export default function Menu(){
    const datacar = localStorage.getItem("items")
    return(
      <nav className="w100 h2 row center b2 posifixedfim " style={{backgroundColor: TenancyColor().backgroundColor}}>
        <div className="w90 h2 row center between ma5">
          
            <Link to="/home" className="w30 h2 column center">
              <img src={IconHome} alt="home"/>
              <div className=" row center">
                <label className="ml5 fs08em fb pp c1">Home</label>
              </div>
            </Link>

            <Link to="/carrinho" className="w30 h2 cont-circ row start borda1 b2 cell-tamanho-metade35 pp">
              <div className="h5 w20 cont-circ50pc fb row center borda1 cell-tamanho-metade30">
                {datacar?<label>+1</label>:<label>0</label>}
              </div>
              <div className="column center w66 h2">
                <img src={IconCar} className="w14 h2 cell-tamanho-metade35"></img>
                <label className="ml5 fs08em fb pp c1">Carrinho</label>
              </div>
            </Link>
            
            <Link to="/pedido" className="w30 h2 fb column center">
              <img src={IconTicket} className="w5 h2 cell-tamanho20"/>
              <label className="ml5 fs08em fb pp c1">Pedidos</label>
            </Link>
        </div>
       
      </nav>
    )
}

