//import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import './App.css';

//COMPONENTES PAGES
import PublicPonto from "./components/pages/PublicPonto";
import Login from './components/pages/Login'

import AdminPonto from "./components/pages/AdminPonto";
import Carrinho from './components/pages/Carrinho'
import Painel from "./components/pages/AdminPainel";
import AdminProduto from "./components/pages/AdminProduto";
import AdminFrete from "./components/pages/AdminFrete";
import AdminCliente from "./components/pages/AdminCliente"
import AdminClienteFornecedor from "./components/pages/AdminClienteFornecedor"
import PageIndex from "./components/pages/PageIndex"
import AdminProdutoCategoria from "./components/pages/AdminProdutoCategoria";
import AdminDadosEmpresa from "./components/pages/AdminDadosEmpresa"
import AdminOrcamentos from "./components/pages/AdminOrcamentos"
import AdminVendaRapida from "./components/pages/AdminVendaRapida"
import AdminEstoqueProduro from "./components/pages/AdminEstoquProduto";
import AdminLeads from "./components/pages/AdminLead"
import AdminLog from "./components/pages/AdminLog";
import TeancyAuth from "./components/midllers/TenancyAuth";
import AdminImagem from "./components/pages/AdminImagem"
import AdminEntregador from "./components/pages/AdminEntregador"
import AdminCustoEmpresa from"./components/pages/AdminCustosEmpresa"
import axios from 'axios';
import PrivateRouter from "./components/midllers/PrivateRouter";
import AdminPix from "./components/pages/AdminPix";
import AdminCaixa from "./components/pages/AdminCaixa";
import Leadpageebook01 from './components/pages/Leadpageebook01'
import Catalogo from "./components/partils/Catalogo";
import AdminLojaVirtual from "./components/pages/AdminLojaVirtual"


import AreaDoCliente from "./components/pages/AreaDoCliente";
import { useEffect, useState } from "react";
import Pedido from "./components/pages/Pedido";
import AdminFeature from "./components/pages/AdminFeature";
import AdminFinanceiroTenancy from "./components/pages/AdminFinanceiroTenancy";
import AdminAssistenteInteligente from "./components/pages/AdminAssistenteInteligente";
import AdminVendaRealizada from "./components/pages/AdminVendasRealizada";
import AdminAgenda from "./components/pages/AdminAgenda";
import AdminFuncionario from './components/pages/AdminClienteFuncionario';

//COMPONENTES PARTLS
//import Menu from "./components/partils/Menu";

//sessionStorage.getItem('keyItemNameg')
const tlocal = localStorage.getItem('keyItemNameg')
const tenancylocal = localStorage.getItem('ClienteTenancy')

//const usernivel = localStorage.getItem('usernivel') 
//axios.defaults.baseURL = "http://getclin.kinghost.net:21031";
//http://automaticas.nodejs10f05.kinghost.net

// autometricas.com.br:21031

axios.defaults.baseURL = "https://autometricas.com.br:21069";
//axios.defaults.baseURL ="http://localhost:21003"

//axios.defaults.baseURL = "https://autometricas.nodejs10f05.kinghost.net";
//axios.defaults.baseURL ="http://localhost:21003"


//axios.defaults.baseURL ="http://192.168.0.110:21031"   
//axios.defaults.baseURL ="https://melhorcatalogo.online/"
//axios.defaults.baseURL ="http://localhost:21003"
//axios.defaults.baseURL ="http://192.168.221.5:21031"
//axios.defaults.baseURL ="http://10.0.0.57:3002"
//axios.defaults.baseURL ="http://192.168.0.120:3002" 

axios.defaults.headers.common['token'] = tlocal;
axios.defaults.headers.common['id_tenancy'] = tenancylocal;
axios.defaults.headers.common['device'] = navigator.userAgent;


//axios.defaults.withCredentials = true;
function App() {
  const [usernivel, setUsernivel] = useState(1);

  useEffect(() => {
        const nivel = localStorage.getItem('usernivel');
        nivel&&setUsernivel(nivel);
        //console.log("Verificando usernivel:", nivel);
}, []);


  return (
    <BrowserRouter>

    <Routes>
      <Route path="/" element={<PageIndex />} />

      
      <Route path="/login" element={<Login />} />
      <Route path="/ebookdigitalcomofaturarmaisem2025" element={<Leadpageebook01/>} />
      
      <Route path="/home" element={<Catalogo />} />
      <Route path="/carrinho" element={<Carrinho />} />
      <Route path="/pedido" element={<Pedido/>} />
      
      <Route path="/loja/:id" element={<TeancyAuth />} />
      <Route path="/ponto/:id/:funcionario/:tenancy" element={<PublicPonto/>} />
      
      <Route path="/areadocliente/orcamentoAuth0/:id/:ch" element={<AreaDoCliente/>}/>

      <Route element={<PrivateRouter/>}>
        <Route path="/adminentregador" element={<AdminEntregador />} />
        <Route path="/adminponto" element={<AdminPonto />} />
        <Route path="/adminassistenteinteligente" element={<AdminAssistenteInteligente />} />
        
       
          <Route path="/painel" element={  usernivel >= 19 ?<Painel />:<p>Carregando...</p>} />

          <Route path="/admincustoempresa" element={<AdminCustoEmpresa />} />
          <Route path="/adminlog" element={<AdminLog />} />
          <Route path="/adminvendarealizada" element={<AdminVendaRealizada />} />
          <Route path="/adminpix" element={<AdminPix />} />
          <Route path="/admincaixa" element={<AdminCaixa />} />
          <Route path="/admialojavirtual" element={<AdminLojaVirtual />} />
          
        
        <Route path="/adminproduto" element={<AdminProduto />} />
       
        <Route path="/admincliente" element={<AdminCliente />} />
        
        <Route path="/adminclientefornecedor" element={<AdminClienteFornecedor />} />
        <Route path="/admincategoriaproduto" element={<AdminProdutoCategoria />} />
        <Route path="/admindadosempresa" element={<AdminDadosEmpresa />} />
        <Route path="/adminpedido" element={<AdminOrcamentos />} />
        <Route path="/adminvendarapidaEdtar/:param1" element={<AdminVendaRapida />} />
        <Route path="/adminvendarapida" element={<AdminVendaRapida />} />
        <Route path="/adminlead" element={<AdminLeads />} />
        <Route path="/admiagenda" element={<AdminAgenda />} />
        <Route path="/adminprodutoestoque" element={<AdminEstoqueProduro />} />
        <Route path="/adminimagen" element={<AdminImagem />} />
        <Route path="/adminfrete" element={<AdminFrete />} />
        <Route path="/adminfeature" element={<AdminFeature />} />
        <Route path="/adminfinanceirotenancy" element={<AdminFinanceiroTenancy />} />
        <Route path="/adminclientefuncionario" element={<AdminFuncionario />} />
        
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
