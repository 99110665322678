import React, { useState } from "react";
import { Chart } from "react-google-charts";

// Função para abreviar os meses
const abbreviateMonth = (monthName) => {
  const abbreviations = {
    janeiro: "jan",
    fevereiro: "fev",
    março: "mar",
    abril: "abr",
    maio: "mai",
    junho: "jun",
    julho: "jul",
    agosto: "ago",
    setembro: "set",
    outubro: "out",
    novembro: "nov",
    dezembro: "dez",
  };
  return abbreviations[monthName.toLowerCase()] || monthName; // Retorna a abreviação ou o nome original se não encontrar
};

function ChartPedidosMes({ pedidos }) {
  const [selectedMonth, setSelectedMonth] = useState("all");

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filterPedidosByMonth = (pedidos, selectedMonth) => {
    if (selectedMonth === "all") {
      return pedidos;
    }

    const month = parseInt(selectedMonth);
    return pedidos.filter((pedido) => {
      const pedidoMonth = new Date(pedido.createdAt).getMonth() + 1;
      return pedidoMonth === month;
    });
  };

  // Agrupa os pedidos por ano e mês
  const pedidosByYearAndMonth = filterPedidosByMonth(pedidos, selectedMonth).reduce((acc, pedido) => {
    const date = new Date(pedido.createdAt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Janeiro é 0, então adicionamos 1

    if (!acc[year]) {
      acc[year] = {};
    }

    if (!acc[year][month]) {
      acc[year][month] = 0;
    }

    acc[year][month] += 1;

    return acc;
  }, {});

  // Prepara os dados para o gráfico
  const chartData = [["Mês", ...Object.keys(pedidosByYearAndMonth).map(year => `total  ${year} `)]];

  // Cria um array com todos os meses para garantir que todos os anos tenham dados para cada mês
  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  months.forEach(month => {
    const monthName = new Date(2023, month - 1).toLocaleString('default', { month: 'long' }); // Nome completo do mês
    const abbreviatedMonth = abbreviateMonth(monthName); // Abrevia o nome do mês
    const row = [abbreviatedMonth]; // Usa a abreviação no gráfico

    Object.keys(pedidosByYearAndMonth).forEach(year => {
      row.push(pedidosByYearAndMonth[year][month] || 0);
    });

    chartData.push(row);
  });

  // Cores para cada ano
  const colors = ["#F3123C", "#1F78B4", "#33A02C", "#E31A1C", "#FF7F00", "#6A3D9A", "#A6CEE3", "#B2DF8A", "#FB9A99", "#FDBF6F", "#CAB2D6", "#FFFF99"];

  return (
    <div className="w100 column center">
      <div className="w90">
        <h5>Serviços/Vendas</h5>
        <label htmlFor="month-select">Filtrar por mês:</label>
        <select id="month-select" value={selectedMonth} onChange={handleMonthChange}>
          <option value="all">Todos</option>
          <option value="1">Janeiro</option>
          <option value="2">Fevereiro</option>
          <option value="3">Março</option>
          <option value="4">Abril</option>
          <option value="5">Maio</option>
          <option value="6">Junho</option>
          <option value="7">Julho</option>
          <option value="8">Agosto</option>
          <option value="9">Setembro</option>
          <option value="10">Outubro</option>
          <option value="11">Novembro</option>
          <option value="12">Dezembro</option>
        </select>
      </div>

      <Chart
        chartType="LineChart"
        data={chartData}
        width="100%"
        height="400px"
        legendToggle
        options={{
          curveType: "function", // Suaviza a linha
          series: Object.keys(pedidosByYearAndMonth).reduce((acc, year, index) => {
            acc[index] = { color: colors[index % colors.length], lineWidth: 3 }; // Atribui uma cor única para cada ano
            return acc;
          }, {}),
          hAxis: {
            textStyle: {
              fontSize: 10, // Tamanho da fonte do eixo horizontal (meses)
            },
          },
        }}
      />
    </div>
  );
}

export default ChartPedidosMes;
/*

Grafico não separando por ano codigo reduzido

import React, { useState } from "react";
import { Chart } from "react-google-charts";

function ChartPedidosMes({ pedidos }) {

  const [selectedMonth, setSelectedMonth] = useState("all");
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const filterPedidosByMonth = (pedidos, selectedMonth) => {
    if (selectedMonth === "all") {
      return pedidos;
    }
  const month = parseInt(selectedMonth);
    return pedidos.filter((pedido) => {
      const pedidoMonth = new Date(pedido.createdAt).getMonth() + 1;
      return pedidoMonth === month;
    });
  };
  const pedidosByDay = filterPedidosByMonth(pedidos, selectedMonth).reduce(
    (acc, pedido) => {
      const pedidoDate = new Date(pedido.createdAt).toLocaleDateString();
      if (!acc[pedidoDate]) {
        acc[pedidoDate] = 1;
      } else {
        acc[pedidoDate] += 1;
      }
      return acc;
    },
    {}
  );
  const chartData = [["Dia", "Serviços"]];
  // Percorre as chaves do objeto em ordem reversa e adiciona os valores ao gráfico
  const pedidosByDayKeys = Object.keys(pedidosByDay).reverse();
  for (let i = 0; i < pedidosByDayKeys.length; i++) {
    const dia = pedidosByDayKeys[i];
    const quantidadePedidos = pedidosByDay[dia];
    chartData.push([dia, quantidadePedidos]);
  }
  return (
    <div className="w100 column center">
      <div className="w90">
        <h5>Serviços no periodo</h5>
        <label htmlFor="month-select" >Filtrar por mês:</label>
        <select id="month-select" value={selectedMonth} onChange={handleMonthChange}>
          <option value="all">Todos</option>
          <option value="1">Janeiro</option>
          <option value="2">Fevereiro</option>
          <option value="3">Março</option>
          <option value="4">Abril</option>
          <option value="5">Maio</option>
          <option value="6">Junho</option>
          <option value="7">Julho</option>
          <option value="8">Agosto</option>
          <option value="9">Setembro</option>
          <option value="10">Outubro</option>
          <option value="11">Novembro</option>
          <option value="12">Dezembro</option>
        </select>
      </div>

      <Chart
        chartType="LineChart"
        data={chartData}
        width="100%"
        height="400px"
        legendToggle
        options={{
          curveType: "function", // Suaviza a linha
          series: {
            0: { color: "#F3123C", lineWidth: 3 }, // Cor e espessura da linha
          },
        }}
      />
    </div>
  );
}

export default ChartPedidosMes;
*/