import { useCallback, useEffect, useState } from 'react'
import axios from "axios";

import SucessAlert from './SucessAlert';
import Menu from '../partils/Menu'
import IconNext from '../../acets/img_icon/next.svg';
import IconCar from '../../acets/img_icon/carrinho.svg'
import { Link } from 'react-router-dom';
import IconBack from '../../acets/img_icon/back.svg'
import TenancyColor from '../midllers/TenancyColor'

export default function Cardapio(props) {
  const [openAddCar, setOpenAddCar] = useState(false)
  const [data, setData] = useState([])
  const [dataDetails, setDataDetails] = useState([])
  const [countSt, setCountSt] = useState(1)
  const [openSucess, setOpenSucess] = useState(false)
  const [imageSt, setimageSt] = useState()
  const [opcionaisData,setOpcionaisData]=useState([])

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedReduce, setSelectedReduce] = useState(0);

  const [stringpesquisa,setStringpesquisa]=useState('')
  const localpreparo = localStorage.getItem('localpreparo')
  useEffect(() => {
//console.log(props.Pesquisa)
    if(props.Pesquisa){
      setStringpesquisa(props.Pesquisa)
      if(stringpesquisa){
        axios.post('/api/lojapublic/search/',{nome:stringpesquisa} ).then(resp => {
          setData(resp.data)
        })
      }
    }
    else{
      axios.get(props.KeyCategoria ? '/api/lojapublic/categoria/' + props.KeyCategoria : '/api/lojapublic/').then(resp => {
        setData(resp.data)
        console.log(resp.data)
      })
    }
    //setCountSt(count);     
  }, [props.KeyCategoria,props.Pesquisa,stringpesquisa])
//console.log(props.Pesquisa)
const OpenDetains = function (id) {
  setOpenAddCar(true);

  axios.get('/api/lojapublic/' + id).then(resp => {
    setDataDetails(resp.data[0]);
    //setimageSt(resp.data[0].foto);
    //console.log(resp.data[0])
    axios.get('/api/lojapublic/' + id).then(resp => {
      setOpcionaisData(resp.data);
      setSelectedCheckboxes({});
      setSelectedReduce(0); // Redefinir os estados ao abrir os detalhes de um novo produto
    });
  });
};
  const addToCar = function (idprod,qnt, id, preco,selectedCheckboxes) {//EDITA VER CARREGA OS DADOS DO ITEM A SER EDITADO
    // Criando objeto com dados dos inputs
    setCountSt(1)
    const dataObj = {'id_produto_servico':idprod, 'id_produto_state': Math.floor(Math.random() * 256), 'quantidade': qnt, 'nome': id, 'preco': preco,'opcionais':JSON.stringify(selectedCheckboxes)};
    if (localStorage.getItem('items') === null) {
      // Adicionando um array com um objeto no localstorage
      localStorage.setItem('items', JSON.stringify([dataObj]));
    } else {
      // Copiando o array existente no localstorage e adicionando o novo objeto ao final.
      localStorage.setItem(
        'items',
        // O JSON.parse transforma a string em JSON novamente, o inverso do JSON.strigify
        JSON.stringify([
          ...JSON.parse(localStorage.getItem('items')),
          dataObj
        ])
      );
    }
    setOpenAddCar(false)
    setOpenSucess(true)
    setSelectedCheckboxes({})
    setTimeout(() => {
      setOpenSucess(false)
    }, "1000")
  }

  // 
  const serverUrl = axios.defaults.baseURL
  const tenancylocal = localStorage.getItem('ClienteTenancy')

  const today = new Date(); // Obtém a data atual
const dayOfWeek = today.getDay(); // Obtém o dia da semana (0 - Domingo, 1 - Segunda-feira, ..., 6 - Sábado)

const filteredData = data.filter(namesString => namesString.status !== 6 && // Filtra os produtos desativados
  (!namesString.indisponivel_semana || // Verifica se indisponivel_semana é nulo
  !namesString.indisponivel_semana.includes(dayOfWeek))); // Verifica se o dia da semana atual está presente no array

const final = filteredData.map(namesString => (
  <div className='w100 h7 cont-circ8 row ma5 sombra-small pp b2' key={namesString.id_produto_servico} onClick={() => OpenDetains(namesString.id_produto_servico)}>
    {namesString.foto ?
      <img src={`${serverUrl}${namesString.foto}`} alt="produto" className="w7 cell-tamanho20 h10 column center fb pp b3 cont-circ8 borda1" /> :
      <div className='w7 cell-tamanho20 h10 column center fb pp b15 cont-circ8 borda1'></div>
    }
    <div className='w90 row around cell-tamanho75'>
      <label className="w70 h4 row pp montserrat">{namesString.descricao}</label>
      <label className="w20 h4 row p5 column center">{'R$:' + namesString.preco}</label>
      <label className='fs08em c4 w90'> Mais...</label>
    </div>
  </div>
));
/*
  const filteredData = data.filter(namesString => namesString.status !== 6); // FILTRA OS PRODUTOS DESATIVADOS
  const final = filteredData.map(//MAPEIA TODO O FILTRO
    namesString =>
      <div className='w100 h7 cont-circ8 row ma5 sombra-small pp b2' key={namesString.id_produto} onClick={() => OpenDetains(namesString.id_produto)}>
        {namesString.foto?
        <img src={`${serverUrl}${namesString.foto}`} alt="produto" className="w7 cell-tamanho20 h10 column center fb pp b3 cont-circ8 borda1"/>:
        <div className='w7 cell-tamanho20 h10 column center fb pp b15 cont-circ8 borda1'></div>
        }
        <div className='w90 row around cell-tamanho75'>
          <label className="w70 h4 row pp montserrat">{namesString.nome}</label>
          <label className="w20 h4 row p5 column center">{'R$:' + namesString.preco_final.toFixed(2)}</label>
          <label className='fs08em c4 w90'>{namesString.ingredientes.substring(35,0)} Mais...</label>
        </div>
      </div>
  )
*/
const capaimgtenancy = localStorage.getItem('localcapaimg')
const divStyle = {
  backgroundImage: `url(${capaimgtenancy})`, // Adicionando `url()`
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};
const handleCheckboxChange = (name, checked, preco_opcional) => {
  setSelectedCheckboxes((prevSelectedCheckboxes) => {
    const updatedCheckboxes = {
      ...prevSelectedCheckboxes,
      [name]: {
        checked,
        preco_opcional,
      },
    };
    const selectedCount = Object.values(updatedCheckboxes).reduce(
      (count, item) => count + (item.checked ? 1 : 0),
      0
    );
    if (dataDetails.limite !== 0 && selectedCount > dataDetails.limite) {
      return prevSelectedCheckboxes; // Limite atingido, não atualize o estado
    }
    setSelectedReduce(selectedCount);
    return updatedCheckboxes;
  });
};

  

  let totalPreco = 0;
  for (const item in selectedCheckboxes) {
    if (selectedCheckboxes[item].checked) {
      totalPreco += selectedCheckboxes[item].preco_opcional;
    }
  }
  //console.log(dataDetails)

  return (
    <>
      <Menu />
      {openSucess && <SucessAlert />}

      {openAddCar &&
        <div className='fundo-opaco w100 h100 posifixedinicio column center '>
          <div className='b2 w30 cont-circ8 column center cell-tamanho '>
          <div className='b2 w5 cell-tamanho10 abstop10left10 column center h5 ma10b0 pp cont-circ15 cell-left5 borda1' onClick={() => setOpenAddCar(false) & setCountSt(1) & setDataDetails([])}>
            <img src={IconBack}></img>
          </div>
        
          {dataDetails.foto?
          <div className='w100 b1 column center cont-circ-root-bottom' style={{backgroundImage: `url(${serverUrl}${dataDetails.foto})`,backgroundPosition:'center',backgroundSize:'cover'}}>
            <label className='borda1 cont-circ15 p7 abstop10le b2 cell-left5'>
              {localpreparo === null || localpreparo === "" || localpreparo === 'null'? "25-35min": localpreparo}
            </label>
            <img src={`${serverUrl}${dataDetails.foto}`} alt="produto" className="w100 he40 cont-circ-root-bottom "/>
          </div>:
          <div className='w100 h40 b15 column center cont-circ-root-bottom'>
             <h5 className='fs2em w90 ma10b0 fb montserrat c1'>{tenancylocal}</h5>
          </div>
      }
            <div className='h100 w100 column between '>
              <div className='he50 w100 row center overflowY'>
                <h5 className='fs1p3em w90 ma10b0 fb montserrat'>{dataDetails.descricao}</h5>
                <div className='c4 fs1p1em w90 h50 overflowY'>
                    <pre className='c4 fs1p1em w90 overflowY'>{dataDetails.descricao_detalhada}</pre>
                    
                </div>
                <label className='h15'>{tenancylocal}</label>
              </div>
              <div className='sombra w30 cont-circ-root column center posifixedfim b2 cell-tamanho'>
                <div className='ma10b0 w90'>
                  <div className='row between w100'>
                    <h6 className='fb montserrat'>R$: {dataDetails.preco? (dataDetails.preco* countSt+totalPreco):<>Carregando...</>} </h6>
                    <div onClick={() => setOpenAddCar(false) & setCountSt(1) & setDataDetails([]) & setSelectedReduce(null)} className='borda1 pp cont-circ15 b2 h6 row center w24'>
                      <img src={IconBack} className='w20'></img>
                      <label className='pp'>Voltar</label>
                    </div>
                    <div className='borda1 cont-circ15 b2 row'>
                      <div onClick={() => setCountSt(countSt + 1)} className='cont-circ50pc b2 c1 p14 pp'>+</div>
                      <label className='mac0l8'>{countSt}</label>
                      <div onClick={() => setCountSt(countSt > 1 && countSt - 1)} className='cont-circ50pc b2 c1 p14 pp'>-</div>
                    </div>

                  </div>
                </div>
                <div className='h5 w100 column between ma5 posiabsrelativebotton0'>
                  
                {countSt && dataDetails.preco &&
                  <button onClick={() => addToCar(dataDetails.id_produto_servico, countSt, dataDetails.descricao, dataDetails.preco, selectedCheckboxes)} className='w90 h5 cont-circ15 row center' style={{backgroundColor: TenancyColor().backgroundColor}}>
                    Adicionar ao carrinho
                    <img src={IconCar} className="w6 cell-tamanho10" alt="Ícone do carrinho"></img>
                  </button>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <section className='h100 column start'>
        <div className='w90 row start '>
          <div className='w100 h20 b3 column center ma10b0 'style={divStyle}>
            <label className='montserrat'>
              {//tenancylocal
              }
            </label>
          </div>
          <label className='fs1p3em ma10b0'>Produtos / Serviços</label> 
        </div>
        <div className='h20 w90 cont-circ8 column start'>
          {data.length === 0 ? <label className='w100 h20 column center'>Sem itens</label> : final}
        </div>
      </section>

    </>
  )
}