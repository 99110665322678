import React, { useState ,} from "react";
import {useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";


export default function AdminPonto(){

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [wifiNome, setWifiNome] = useState(null);
  const [status, setStatus] = useState("");

  const { id } = useParams()
  const { funcionario } = useParams()
  const { tenancy } = useParams()

  const dateH = new Date().toLocaleTimeString();
  const navigate = useNavigate();

  // Obtém a localização do colaborador
  const obterLocalizacao = (params) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setStatus("Localização obtida com sucesso!");
        },
        (error) => {
          setStatus("Erro ao obter localização. Ative o GPS.");
        }
      );
    } else {
      setStatus("Geolocalização não suportada no navegador.");
    }
  };

  // Obtém o nome da rede Wi-Fi (SSID) -> Funciona apenas em conexões seguras (HTTPS)
  const obterWifi = async () => {
    if ("navigator" in window && navigator.connection) {
      try {
        const netInfo = await navigator.connection;
        if (netInfo.type === "wifi") {
          setWifiNome(netInfo.downlinkMax || "Rede desconhecida");
          setStatus("Rede identificado!");
        } else {
          setWifiNome("Wi-Fi Não identificado!");
          setStatus("Rede identificado");
        }
      } catch (error) {
        setStatus("Não foi possível obter o nome da rede Wi-Fi.");
        setWifiNome("Wi-Fi Não identificado! catch")
      }
    } else {
      setStatus("API de Wi-Fi não suportada no navegador.");
      setWifiNome("Wi-Fi Não identificado! Navg")
    }
  };

  // Envia os dados para o backend
  const registrarPonto = async () => {
    const dadosPonto = {
      latitude,
      longitude,
      rede:wifiNome,
      hora:dateH
    };
    try {//ponto/5/joao/acsports
      const response = await axios.post(`/api/ponto/${5}/${funcionario}/${tenancy}`, dadosPonto);
      setStatus("Ponto registrado com sucesso!");
      alert('Ponto registrado com sucesso!')
      navigate('/login'); 
    } catch (error) {
      setStatus("Erro ao registrar o ponto.");
    }
  };

  return (
    <div className="w100 h100 column center">
      <h2 className="pcb20">{tenancy}</h2>
      <h5>Relógio de ponto</h5>
      <div className="row between w30 cell-tamanho90">
        <p className="fs1p3em fb">Hora:{dateH}</p>
        {latitude&&wifiNome?<button onClick={registrarPonto}>Registrar Ponto</button>:<div className="b19 c3 p10 cont-circ borda1">Registrar Ponto</div>}
      </div>
      
      <div className="w30 row between pcb10 cell-tamanho90">
        <button onClick={obterLocalizacao}>Localização</button>
        <button onClick={obterWifi}>Obter Wi-Fi</button>
      </div>

      <div className="w30 column cell-tamanho90">
        <div className="row between w100 linha">
          <p>Funcionario:{/*'Registro: 342'+id+(id-2)*/}</p>
          <p>{funcionario}</p>
        </div>
        <div className="row between w100 linha">
          <p>Status:</p>
          <p>{status}</p>
        </div>
        <div className="row between w100 linha">
          <p>Localização:{/*'Registro: 342'+id+(id-2)*/}</p>
          <p>{latitude}, {longitude}</p>
        </div>
        <div className="row between w100 linha">
          <p>Rede:{/*'Registro: 342'+id+(id-2)*/}</p>
          <p>Wi-Fi|3g|4g</p>
        </div>
      

        <label className="pcb50">Pare registrar o ponto clique em Localização e depois em Obter Wi-Fi quando o botão Registrar Ponto estiver vermelho clique sobre ele para registrar o ponto.</label>
      </div>
      

      
      <p>Auto Métricas</p>
    </div>
  );
};
