import  { useEffect,useState } from 'react'
import axios from "axios";

export default function ItensFuncionario(props){
    const [openSucess,setOpenSucess]=useState(true)
    const [dataItens, setDataItens] = useState([])
    const [valorsalario, setValorSalario] = useState("");
    useEffect(()=>{
      rec();
    },[props.id,props.dtini,props.dtfim])
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
      
        //setCountSt(count);     rt
    }
    
    //console.log(props)
    function gerarrelatoriofuncionarios(id){
      axios.get(`/api/dashboard/pivoorcaprod/contporfuncionario/${props.dtini}/${props.dtfim}/${id}`).then(resp=>{
        setDataItens(resp.data)
        console.log(resp.data)
        setOpenSucess(false)
      })
   }
   
   const totalProdutos = dataItens.reduce((total, item) => total + item.quantidade * item.preco, 0);
   const totalRecebido = dataItens
    .filter(item => item.orcamento.status === 3)
    .reduce((total, item) => total + item.quantidade * item.preco, 0);

   //const totalareceber = 
const final = dataItens.map(namesString => (
  <div className='w100 row between linha' key={namesString.id_pivo_orcamento_produto}>
        <label className='fs09em w60'>{namesString.produto_servico.descricao}</label>
        <label  style={{
                    background: `${
                      namesString.orcamento.status ===1
                        ? "#68A652"
                        : namesString.orcamento.status ===2
                        ? "#FFEC87"
                        : namesString.orcamento.status ===3
                        ? "#B1FF95"
                        : namesString.orcamento.status ===4
                        ? "#FFA487"
                        : "transparent"
                    }`,
                    width:'20%',
                    fontSize:'0.8em',
                    textAlign:'center'
                }}>{
                    
                  namesString.orcamento.status === 1
                ? "Em Serv."
                : namesString.orcamento.status === 2
                ? "Pg Pend"
                : namesString.orcamento.status === 3
                ? "Pago"
                : namesString.orcamento.status === 4
                ? "Cancelado"
                : null
                }</label>
        <label className='fs09em w15'>R$: {namesString.quantidade*namesString.preco}</label>
  </div>
));
  
    return(
      <div className='w70'>
        <div className='w100 fim'>
          {valorsalario&&openSucess?<button onClick={()=>gerarrelatoriofuncionarios(props.id)}>Listar</button>:<label>...</label>}
        </div>
        <div className='pcb10'> 
           <label className='fb'>Serviços realizados no periodo:</label>
        </div>
       
        {final}
        <div className='column pcb10 w100'>
           <div className='pcb10 w100'> 
              <label className='fb'>Balanço final do funcionario:</label>
            </div>
          <h6 className='w100 '>Total R$: {totalProdutos.toFixed(2)}</h6>
          {/*<h6 className='w100'>Recebido: R$: 340</h6>
          <h6 className='w100'>A receber: R$:1050</h6>*/}
            <h6 className='w100'>
              Salario/Custo R$: {!valorsalario ? 'Defina o valor' : `R$ ${valorsalario}`}
            </h6>
            {openSucess&&<input 
              type='number' 
              value={valorsalario} 
              onChange={(e) => setValorSalario(e.target.value)} 
            />}
            <h6 className='w100'>Recebido R$: {totalRecebido}</h6>
            <h6 className='w100'>A receber:{totalProdutos-totalRecebido}</h6>
            <h6 className='w100'>
              Resultado futuro para a oficina <strong>R$: {totalProdutos - (parseFloat(valorsalario) || 0)}</strong>
            </h6>
            <h6 className='w100'>
              Resultado realizado para a oficina <strong>R$: {totalRecebido - (parseFloat(valorsalario) || 0)}</strong>
            </h6>
        </div>
      </div>
    )
}