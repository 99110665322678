import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES


//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'
import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'
import AdminHeader from '../partils/AdminHeader'

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

export default function AdminPonto(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Ponto',
        mainstring:'nome',
        url:'/api/ponto/',
        url2:'',
        search:'/api/leads/search/',
        //urlOptions1:'/api/produtocategoria',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    ////    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        nome: '',telefone:'', email: '',porte: ''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    //const[dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            setData(resp.data)
            console.log(resp.data)
        })
            
    }
    //console.log(data)
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id).then(()=>{
            rec();
            
        })
    }
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
        })
    }
   


    const groupedData = data.reduce((acc, item) => {
        const date = new Date(item.createdAt).toLocaleDateString('pt-BR');
        if (!acc[date]) acc[date] = [];
        acc[date].push(item);
        return acc;
    }, {});
    
    const final = Object.entries(groupedData).map(([date, items]) => {
        // Ordena as batidas de ponto do dia por horário
        items.sort((a, b) => a.hora.localeCompare(b.hora));
    
        // Calcula total de horas trabalhadas
        let totalHoras = 0;
        if (items.length >= 2) {
            const entrada = dayjs(`${date} ${items[0].hora}`, "DD/MM/YYYY HH:mm:ss");
            const saida = dayjs(`${date} ${items[items.length - 1]?.hora}`, "DD/MM/YYYY HH:mm:ss");
            totalHoras = saida.diff(entrada, "hour", true).toFixed(2);
        }
       
        return (
            <div key={date} className="w100 b2 borda1 column center ma5">
                {/* Cabeçalho do Dia */}
                <div className='w100 b33 pcb10 row center'>
                    <label className="w90 fs1p3em c1 fb">{date}</label>
                </div>
    
                {/* Listagem das Batidas */}
                {items.map(namesString => (
                    <div key={namesString.id_ponto} className="w90 linha row between">
                        <div className='w5'><img src={IconChecked} alt="Checked Icon" /></div>
                        <div className='w10 cell-tamanho-metade35 fb'>{namesString.nome}</div>
                        <div className='w15 cell-none'>
                            {parseInt(namesString.hora.split(':')[0], 10) >= 6 && parseInt(namesString.hora.split(':')[0], 10) < 11 && 
                                <label className='fs06em b25 p5 cont-circ'>Primeiro ponto</label>}
                            {parseInt(namesString.hora.split(':')[0], 10) >= 12 && parseInt(namesString.hora.split(':')[0], 10) < 13 && 
                                <label className='fs06em b24 p5 cont-circ'>Início do intervalo</label>}
                            {parseInt(namesString.hora.split(':')[0], 10) >= 13 && parseInt(namesString.hora.split(':')[0], 10) < 17 && 
                                <label className='fs06em b32 p5 cont-circ'>Fim do intervalo</label>}
                            {parseInt(namesString.hora.split(':')[0], 10) >= 17 && 
                                <label className='fs06em b29 p5 cont-circ c3'>Fim do expediente</label>}
                        </div>
                        <div className='w15 cell-margin5 c4 cell-tamanho-metade35 fb fs1p3em'>{namesString.hora}</div>
                        <div className='w10 fs08em cell-texto-p c4 cell-tamanho-metade30'>{namesString.latitude}</div>
                        <div className='w10 fs08em cell-texto-p c4 cell-tamanho-metade30'>{namesString.longitude}</div>
                        <div className='w25 fs08em cell-texto-p c4 cell-tamanho-metade50'>
                            {new Date(namesString.createdAt).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}
                        </div>
                        <label
                            className='b3 w10 fs06em cell-tamanho-metade30 cen pcb3 pp'
                            onClick={() => window.open(`https://www.google.com/maps?q=${namesString.latitude},${namesString.longitude}`, '_blank')}
                        >
                            Mapa
                        </label>
                    </div>
                ))}
    
                {/* Resumo do Dia */}
                <div className='w90 mt10 b2 p5 row between'>
                    <p><strong>Total de batidas:</strong> {items.length}</p>
                    <div className='column'>
                        <p><strong>Horas trabalhadas:</strong> {totalHoras}h</p>
                        <label className='fs06em'>Sem levar em conta o intervalo, debite 1h ou 2h</label>
                        <label className='fs06em'>Debitando 1h = {totalHoras-1} Debitando 2h = {totalHoras-2}</label>
                    </div>
                    
                </div>
            </div>
        );
    });
    

    return(
        <>
         <section className='h100 row between b31'>
            
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center celular">
                    
                    <div className="w90 row between cell-tamanho celular">
                        <div className="h100 w100 cont-circ3  column cell-tamanho90 cell-paddingcb10">
                       {/* <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w80'>
                                <input type="text" name="nome" onChange={onChange}/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar"/>
                            </div>
                        </form>
                        */}
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                           {/* <div className='row between w100 h5 cell-none'>
                                <div className='w5 fs08em fb'>St:</div>
                               
                                <div className='w15 fs08em fb'>Nome:</div>
                                <div className='w10 fs08em fb'>Hora cliente:</div>
                                <div className='w10 fs08em fb'>latitude:</div>
                                <div className='w10 fs08em fb'>longitude:</div>
                                <div className='w25 fs08em fb'>Hora servidor:</div>
                                <div className='w10 fs08em fb'>Mapa:</div>
                            </div>*/}
                            <div className='w100'>
                                {data.length > 0 ? final : <div className='w100 h70 column center'> <h5 className='fb'>:(</h5>Ops... Nenhum ponto registrado!<label>Entre em contato com o administrador do sistema</label></div>}
                            </div>
                        </div>     
                    </div>
                </div>
            </div>
            
        </section>
      </>
    )
}






 //COMPONENTES DE LOOP==============================
    //    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    {/*const final = data.map(namesString => (
        <div className='w100 h4 row ma5 between linha' key={namesString.id_ponto}> 
            <div className='w5'><img src={IconChecked} alt="Checked Icon"/></div>
            <div className='w15 cell-tamanho80 fb'>{namesString.nome}</div>
            <div className='w10 cell-tamanho cell-margin5 c4'>{namesString.hora}</div>
            <div className='w10 cell-texto-p c4'>{namesString.latitude}</div>
            <div className='w10 cell-texto-p c4'>{namesString.longitude}</div>
            <div className='w25 cell-texto-p c4'>{namesString.createdAt}</div>
            <button 
                className='b3 w10 fs08em'
                onClick={() => window.open(`https://www.google.com/maps?q=${namesString.latitude},${namesString.longitude}`, '_blank')}
            >
                Mapa
            </button>
        </div>
    ));
    */}