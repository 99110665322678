import axios from 'axios';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Select from 'react-select';

import Menu from '../partils/Menu'
import Loading from '../../acets/img_icon/loading.gif'
import Logopix from '../../acets/img_icon/pixbclogo.png'

import ImgSucess from '../../acets/img_icon/sucess.svg'

import ImgPrimeiraEtapa from '../../acets/img_icon/1de3.svg'
import ImgSegundaEtapa from '../../acets/img_icon/2de3.svg'
import ImgTerceiraEtapa from '../../acets/img_icon/3de3.svg'

import TenancyColor from'../midllers/TenancyColor'

export default function Carrinho() {

  const initialValues = { 
    nome: '', 
    telefone: '',
    email: '', 
    senha: '',
    forma_de_pagamento: 'CART.CRED', 
    estado: '', 
    cidade: '', 
    bairro: '', 
    rua: '', 
    numero: '', 
    complemento: '',
    referencia: '',
    id_produto:'',
    id_pedido:'',
    quantidade:'',
    preco_final:'',
    troco:'0',
    status:'1',	
    pix:'0',
    observacoes:'',
    placa:'online',
    carro:'online',
    codigo_acess:'online',
    frete:'retirar no local'
  }

  const [dadosPessoais, setDadosPessoais] = useState(initialValues)
  const [carrinho, setCarrinho] = useState([{ "id_produto_state": "0", "nome": "Sem itens" }])
  const datacar = localStorage.getItem("items")
  const tenancylocal = localStorage.getItem('ClienteTenancy')
  //const datauser = localStorage.getItem("dadosCard360user#58e3")
  const [datab,setDatab]=useState()
  const [openModalpix,setopenModalpix]=useState()
  //ABRIR MENU CHECKOUT
  const [openProgress, setOpenProgress] = useState(false)
  //STADES DE PROGRESSO CHECKOUT
  const[openNome,setOpenNome]=useState()
  const[openEndereco,setOpenEndereco]=useState()
  const[openFpgto,setOpenFpgto]=useState()
  const[openFpgtoPix,setOpenFpgtoPix]=useState(false)
  const[qrcode,setQrcode]=useState()
  const[opemSendWpp, setopemSendWpp]=useState(false)
  const[alertInputDados, setalertInputDados]=useState(false)
  const[txfreteProximidade, setTxfreteProximidade]=useState(false)
  const[txfreteDistante, setTxfreteDistante]=useState(false)
  const[statusEmpresa, setStatusEmpresa]=useState(false)

  const [dataInput, setDataInput] = useState([])

  const[precocomfrete, setPrecocomfrete]=useState(0)
  //const [values, setValues] = useState(initialValues2)
  const [pedidoId, setPedidoId] = useState()
  useEffect(() => {
    rec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //console.log(dadosPessoais)
  //const dsewetdasdeasvrasf5w4fss = localStorage.getItem('edf5654sde4d888safw2*/#e3ed')
  function rec() {
    const tenancylocal = localStorage.getItem('ClienteTenancy')
    
    localStorage.getItem("items") ? setCarrinho(JSON.parse(datacar)) : setCarrinho([{ "id_produto_state": "0", "nome": "Sem itens" }])
  
    axios.get('api/empresa/tenancy/'+tenancylocal).then(resp=>(
      setDatab(resp.data.whatsapp),
      setopenModalpix(resp.data.pix_estatico),
      setTxfreteProximidade(resp.data.taxa_frete_proximo),
      setTxfreteDistante(resp.data.taxa_frete_distante),
      setStatusEmpresa(resp.data.status)
      //console.log(resp.data)
    ))
    axios.get('api/frete').then(resp=>{
      setDataInput(resp.data)
    })

  }
  //console.log(txfreteDistante+ 'taxaa')
  function onChange(ev) {
    const { name, value } = ev.target;
    setDadosPessoais({ ...dadosPessoais, [name]: value });
  
    // Atualize o valor de precocomfrete com base no frete selecionado
    if (name === 'frete') {
      const freteValue = parseFloat(value);
      const totalComFrete = precoTotalGeral + freteValue;
      setPrecocomfrete(totalComFrete);
    }
  }

//console.log("Preço total: " + precoTotal);


const testeeee = JSON.parse(datacar)
//let precoTotalopc = 0;
let precoTotalGeral = 0;

console.log(testeeee)
const final = testeeee?.map(item => {
  let precoTotalOpc = 0;
  if (item.opcionais) {
    const opcionaisObj = JSON.parse(item.opcionais);
    for (const opcional in opcionaisObj) {
      if (opcionaisObj[opcional].checked) {
        const precoOpcional = opcionaisObj[opcional].preco_opcional;
        precoTotalOpc += precoOpcional;
      }
    }
  }
  const precoFinal = (item.quantidade * item.preco + precoTotalOpc).toFixed(2);
  precoTotalGeral += parseFloat(precoFinal);
  return (
    <div key={item.id_produto_state} className="w90 bbot1 h5 row between">
      <label className='w10'>{item.quantidade}</label>
      <label className='w50'> {item.nome}</label>
      <div className='w20 row'>
        <label className='fs08em'>R$:</label>
        <label>{item.preco}</label>
      </div>
      <div className='w20 row'>
        <label className='fs08em'>R$:</label>
        <label>{precoFinal}</label>
      </div>
    </div>
  );
});

  //======================MENSAGEM WPP====================================
  const cabecalho = encodeURIComponent(
    "\n" + "Olá, meu nome é " + dadosPessoais.nome +
    " e o meu endereço é :" +
    "\n" + "Bairro: " + dadosPessoais.bairro +
    "\n" + "Rua: " + dadosPessoais.rua +
    "\n" + "Numero: "+dadosPessoais.numero+
    "\n" + "a forma de pagamento será: " + dadosPessoais.forma_de_pagamento +
    "\n"+" eu vou querer: "+"\n"
  )
  
  var total = carrinho.reduce(getTotal, 0);//PERCORRENDO o array para somar o total dos itens
  function getTotal(total, item) {//PERCORRE
    return total + (item.quantidade * item.preco);
  }
  //console.log(carrinho) // ADICIONA OS OPICIONAIS E LISTA OS PRODUTOS
  const finalIensString = carrinho.map(item => {
    let optionalString = "";
    if (item.opcionais) {
      const optionalObj = JSON.parse(item.opcionais);
      const optionalNames = Object.entries(optionalObj).filter(([name, value]) => value).map(([name, value]) => name);
      if (optionalNames.length > 0) {
        optionalString = "\nOpicionais: " + optionalNames.join(", ");
      }
    }
    return encodeURIComponent("\n" + item.quantidade + " " + item.nome + " R$: " + item.preco + optionalString);
  });
 
  let precoTotal = 0;
  const finalFreteString = encodeURIComponent("\n Frete R$: " + dadosPessoais.frete)

  const finalTrocoString = encodeURIComponent("\n Troco para R$: " + dadosPessoais.troco)

  const finalObsString = encodeURIComponent("\n \n Observações: " + dadosPessoais.observacoes)

  const finalTotalString = encodeURIComponent(
    "\n ===== Total R$: " + (precocomfrete ? precocomfrete.toFixed(2) : "") + "=====" +
    "\n ____________________________________" +
    //"\n          Acompanhe o pedido" +
    //"\n https://autometricas.com.br/pedido/CheckPedidoAuth/" + pedidoId +
    //"\n ____________________________________" +
    "\n                Tecnologia" +
    "\n www.autometricas.com.br"
  );


  
  //======DATA======
  var data = new Date();
  var dia = String(data.getDate()).padStart(2, '0');
  var mes = String(data.getMonth() + 1).padStart(2, '0');
  var ano = data.getFullYear();
  const dataAtual = ano + '/' + mes + '/' + dia;
  const finalData = encodeURIComponent("\n ====== " + dataAtual + " ======")
  
  function sedmessageWppAPI() {// BOTAO PARA ABRIR O LINK
    if (dadosPessoais.nome === "" || dadosPessoais.bairro === "" || dadosPessoais.rua === "" || dadosPessoais.numero === "") {
      alert("Atençao, preencha todos os campos")
    }
    else {
      const linkreadprocess="\n"+" http://10.0.0.57:3000/CheckPedidoAuth "+"\n"
      window.location.href = "https://api.whatsapp.com/send?phone=55"+datab+"&text=" + finalData + cabecalho + finalIensString  + finalObsString+ finalTrocoString + finalFreteString + finalTotalString 
    }
    localStorage.setItem('dadosCard360user#58e3', JSON.stringify(dadosPessoais));//SETANDO DADOS PESSOAIS N LS
  }

  const datacarpasrse = JSON.parse(datacar)

  function onSubmit1(ev) {
    ev.preventDefault(); // cadastrando cliente
    //console.log(dadosPessoais)
    dadosPessoais.nome===''||dadosPessoais.telefone===''?
    alert("Preencha todos os campos")
    :
    axios.post('/api/cliente/', dadosPessoais).then(resp => {
      rec();
      setOpenNome(false)
      setOpenEndereco(true)
      /*
        
        axios.post("api/pedido/",{status:'1',forma_de_pagamento:'0',troco:'0',bairro:'0',rua:'0',numero:'0',complemento:'0',frete:dadosPessoais.frete,observacoes:'null',id_cliente:resp.data.id_cliente}).then(resp=>{
          //console.log(resp.data.id_pedido)
            setPedidoId(resp.data.id_pedido)
            //pedidoId
            //localStorage.setItem('pedidoIdListPedidos', resp.data.id_pedido);
            
            const idPedido = resp.data.id_pedido;
            const dataPedido = resp.data.createdAt;
            // Verifica se já existe um array em localStorage para os IDs e datas
            let idDataArray = JSON.parse(localStorage.getItem('pedidoIdDataArray')) || [];
            // Adiciona o novo ID e data ao array
            idDataArray.push({ id: idPedido, data: dataPedido });
            // Armazena o array atualizado em localStorage
            localStorage.setItem('pedidoIdDataArray', JSON.stringify(idDataArray));

            
            datacarpasrse.forEach((objeto, index) => {// ADD IDPEDIDO AOS OBJ DO ARRAY 
              datacarpasrse[index].id_pedido = resp.data.id_pedido;
            });
            for (let i = 0; i < datacarpasrse.length; i++) {// ADD PRODUTOS AO PEDIDO
              axios.post("/api/pivo_pedido_cliente",datacarpasrse[i]).then(resp=>{
              })  
            }
            setOpenNome(false)
            setOpenEndereco(true)
        })
    */})
  }
  function onSubmit2(ev) {//ATUALIZA DADOS COMPLEMENTARES DO PEDIDO
    //console.log(pedidoId)
    ev.preventDefault();
    setopemSendWpp(true)
    //axios.put('/api/pedido/pross/'+pedidoId, dadosPessoais).then(resp =>{
    //console.log(resp.data) 
    //  })
  }

  function gerarQrCode(){
    setOpenFpgtoPix(true)
    dadosPessoais.forma_de_pagamento="PIX"
    axios.get('/api/pixAPIGerarToken').then(resp=>{
      setQrcode(resp.data)
      //console.log(resp.data)
  })
  }
  //const [datapx, setDatapx] = useState('');
  const handleCopiarTexto = async () => {
  //setDatapx(dsewetdasdeasvrasf5w4fss);
  try {
    await navigator.clipboard.writeText(openModalpix);
    alert('Chave pix copiada! Finalize o pedido e acompanhe');
  } catch (error) {
    console.error('Erro ao copiar texto:', error);
  }
};
  function nextEndereco(){
    if(dadosPessoais.rua===''||dadosPessoais.numero===''||dadosPessoais.bairro===''|| dadosPessoais.frete===''){
      alert("Preencha todos os campos")
    }
    else{
      setOpenEndereco(false)
      setOpenFpgto(true)
    }
  }

  function nextNomeClienteFrete(){//
     if(dadosPessoais.frete===''){
      alert("Preencha o campo de frete")
    }
    else{
      setOpenProgress(true)
      setOpenNome(true)
    }
  }
  
const parsedDatacar = JSON.parse(datacar);

//console.log(statusEmpresa)

if (Array.isArray(parsedDatacar) && parsedDatacar.length > 0) {
  try {
    parsedDatacar.forEach(item => {
      let precoItem = parseFloat(item.preco) * parseFloat(item.quantidade);

      if (item.opcionais && item.opcionais !== "{}") {
        let opcionais = JSON.parse(item.opcionais);
        for (let op in opcionais) {
          if (opcionais[op].checked) {
            precoItem += parseFloat(opcionais[op].preco_opcional);
          }
        }
      }

      precoTotal += precoItem;
    });

    //console.log("Preço final da soma dos produtos:", precoTotal);
  } catch (error) {
    
  }
} else {
 
}

/*
const filteredData = dataInput.filter(repsValues => repsValues.status !== 2);
const optionInput = filteredData.map(repsValues => (
  <option key={repsValues.id_frete} value={repsValues.taxa} label={repsValues.bairro + " R$:" + repsValues.taxa} className='c1'/>
));
*/
const filteredData = dataInput.filter(repsValues => repsValues.status !== 2);
const options = filteredData.map(repsValues => ({
  value: repsValues.taxa,
  label: repsValues.bairro + " R$:" + repsValues.taxa
}));
//console.log(dadosPessoais.frete)
const handleSelectChange = (selectedOption) => {
  setDadosPessoais({ ...dadosPessoais, frete: selectedOption.value });
  const freteValue = parseFloat(selectedOption.value);
  const totalComFrete = precoTotalGeral + freteValue;
  setPrecocomfrete(totalComFrete);
};



  return (
    <div>
      <Menu />
      {!datab&&
      <div className='w100 h100 fundo-opaco posiabstop0 left0 column center'>
        <img src={Loading} alt="carregando" className='w30 wmax50'></img>
      </div>}
      <section className='h100 column around w100 b26'>
        
        <div className='h10 row between w40 cell-tamanho90'>
          <h5 className='w90'>Carrinho</h5>
          <h5 className='fb cont-circ15 b3 h5 w33 column center'><Link to="/carrinho">Carrinho</Link></h5>
          <h5 className='fb cont-circ15 borda1 h5 w33 column center'><Link to="/pedido">Pedidos</Link></h5>
        </div>
        {alertInputDados&&
                  <div className='posifixedinicio posiabstop0 h100 w100 z2'>
                    <h5 className=''>ATENÇÃO - Preencha todos  os campos</h5>
                    <button>Continuar</button>
                  </div>
        }
        <div className='w40 h30 cell-tamanho90 h80' >
          {openProgress &&
            <div className='w100 h100 column center posiabstop0 left0 fundo-opaco z16'> 
              <div className='w40 h100 b2 cont-circ8 column center  cell-tamanho'>
                <h4 className='montserrat'>{tenancylocal} ​</h4>
                <div className='row w90'>
                  <h5 className='montserrat'>Checkout ​</h5>
                  <label> - carrinho de compra</label>
                </div>

                
                <div className='w90'>
                  {openNome &&<>
                  <form onSubmit={onSubmit1} autoComplete='off' className='h30 ma10b0' >
                    <h5 className='w100 ma10b0'>Nome completo:</h5>
                    <div className='w100 ma10b0'>
                      <img src={ImgPrimeiraEtapa} alt="primira etapa" className='w30'></img>
                    </div>
                    <label className='c1 fb'>Nome/Apelido:</label>
                    <input type="text" name="nome" onChange={onChange} value={dadosPessoais.nome}></input>
                    <label className='c1 fb'>Telefone com DDD:</label>
                    <input type="number" name="telefone" onChange={onChange} value={dadosPessoais.telefone}></input>
                    <button className='w100 column center h5 ma10b0 pp cont-circ15' type="submit" style={{backgroundColor: TenancyColor().backgroundColor}}>Próximo</button>
                  </form>
                 
                  </>
                  }
                  
                  {openEndereco===true &&
                  <div className='w100 h30 ma10b0 row between'>
                    <h5 className='w100 ma10b0'>Endereço:</h5>
                    <div className='w100 ma10b0'>
                      <img src={ImgSegundaEtapa} alt="primira etapa" className='w30'></img>
                    </div>
                    
                    <div className='w30'>
                      <label className='c1 fb'>Bairro:</label>
                      <input type="text" name="bairro" onChange={onChange} value={dadosPessoais.bairro}></input>
                    </div>

                    <div className='w30'>
                      <label className='c1 fb'>Rua:</label>
                      <input type="text" name="rua" onChange={onChange} value={dadosPessoais.rua}></input>
                    </div>

                    <div className='w30'>
                      <label className='c1 fb'>Numero:</label>
                      <input type="number" name="numero" onChange={onChange} value={dadosPessoais.numero}></input>
                    </div>

                    <div className='w100'>
                      <div className='w100 row'>
                        <label className='c1 fb'>Complemento:</label>
                        <label>(opcional)</label>
                      </div>
                      <input type="text" name="complemento" onChange={onChange} value={dadosPessoais.complemento}></input>
                    </div>
                  
                    <div  className='w100'>
                      <div className='w100 row'>
                        <label className='c1 fb'>Observações do pedido</label>
                        <label>(opcional)</label>
                      </div>
                      <input type="text" onChange={onChange} name="observacoes" value={dadosPessoais.observacoes} placeholder="Ex:Sem molho"/>
                    </div>
                    <button className='w100 column center h5 ma10b0 pp cont-circ15' style={{backgroundColor: TenancyColor().backgroundColor}} onClick={()=>nextEndereco()}>Continuar</button>
                  </div>
                  }
                  {openFpgto===true&&
                  <div className='w100 h30 ma10b0' >
                    <h5 className='w100 fb'>Pagamento:</h5>
                    <div className='w100 ma10b0'>
                      <img src={ImgTerceiraEtapa} alt="primira etapa" className='w30'></img>
                    </div>
                    <form onSubmit={onSubmit2} className="column">
                      <label className='c1 fb w100'>Pagamento na entrega:</label>
                      <label className='c1 w100 ma10b0'>Selecione a forma de pagamento</label>
                      <select name="forma_de_pagamento" onChange={onChange} value={dadosPessoais.forma_de_pagamento}>
                        <option value="CART.CRED">Cartão de credito/debito</option>
                        <option value="DINHEIRO">Dinheiro</option>
                        <option value="PIX">PIX</option>
                      </select>
                      {dadosPessoais.forma_de_pagamento==="DINHEIRO"&&
                      <div className='w100'>
                        <label>Troco para:</label>
                        <input type="number" placeholder='R$:0,00' name='troco' onChange={onChange}></input>
                      </div>
                      }
                      {openModalpix&&<>
                        <h5 className='fb'>Ou</h5>
                        <label className='c1 fb w100'>Pagamento Online:</label>
              
                        <div className='column w100'>
            
                          <div className='w100 borda1 ma25b0 row' onClick={handleCopiarTexto}>
                            <strong className='w90'>Copiar chave</strong>
                            <img className='w40 p10' src={Logopix} alt='Logo' />
                            <label>|</label>
                            <div className='w40 column center'>
                              <label>{openModalpix}</label>
                            </div>
                          </div>
                          {dadosPessoais.forma_de_pagamento==="DINHEIRO"||dadosPessoais.forma_de_pagamento==="CART.CRED"||openFpgtoPix&& //setOpenFpgtoPix
                          <div className='w100 column'>
                              {qrcode?
                              <img className='w40 p10' src={qrcode}></img>:
                              <img src={Loading} alt="carregando" className='w30 wmax50 ma25b0'></img>
                              }
                            {qrcode&&
                            <div className='w100 borda1 ma25b0 row center pendente h5'>
                              <label>Aguardando pagamento</label>
                              <img src={Loading} alt="carregando" className='w10 wmax50 m10'></img>
                            </div>
                            }
                          </div>
                          }
                        </div>
                      </>}
                    {opemSendWpp&&
                    <div className='w100 h100 posifixedinicio left0 column center fundo-opaco'>
                      <div className='b2 w40 h20 cont-circ7 column center cell-tamanho90'>
                        <h5 className='fb ma25b0'>Sucesso!</h5>
                        <img src={ImgSucess} alt="Sucesso" className='w20'></img>
                        <label className='ma25b0 w90 c4'>Quase tudo pronto, envie a verificação via WhatsApp e acompanhe o pedido!</label>
                        <div className='column w90'>
                          <label  onClick={()=>sedmessageWppAPI()&localStorage.removeItem("items")} style={{backgroundColor: TenancyColor().backgroundColor}} className='c3 b37 w100 column center h5 ma10b0 pp cont-circ15'>
                            Finalizar e enviar no WhatsApp
                          </label>
                          {/*
                          <Link to="/pedido" onClick={()=>localStorage.removeItem("items")} className='w100 column center h5 ma10b0 pp cont-circ15' >
                            <label className='c1'>
                              Acompanhar pedido
                            </label>
                          </Link>
                          */}
                          
                        </div>
                      </div>
                    </div>
                    }
                    <div className='row between pcb10 w100'>
                      <button className='w100 column center h5 ma10b0 pp cont-circ15' style={{backgroundColor: TenancyColor().backgroundColor}} type="submit">Finalizar pedido</button>
                      <label className=' w100 column center h5 ma10b0 pp cont-circ15' onClick={()=>{setOpenProgress(false);setOpenFpgto(false)}}>Cancelar pedido</label>
                    </div>
                    </form>
                  </div>
                  }
                  
                </div>
              </div>
            </div>
            }
          {datacar ? 
          <div>
            <div className='w100 ma10b0'>
              <label>Selecione a forma de entrega:</label>
            </div>
            {/*
              <select onChange={onChange} name='frete' value={dadosPessoais.frete} className='borda1 cont-circ15 h5' onClick={() => setPrecocomfrete(Number(precoTotal) + Number(dadosPessoais.frete))}>
              <option value=''>Selecione</option>
              <option value={0}>Retirada = Taxa 0</option>
              {(txfreteProximidade !== 0 && txfreteProximidade !== '0') && (
                <option value={txfreteProximidade}
                onClick={() => setPrecocomfrete(Number(precoTotal) + Number(txfreteProximidade))}
                >Entrega Bairros Proximos R$:{txfreteProximidade}</option>
              )}
              {(txfreteDistante !== 0 && txfreteDistante !== '0') && (
                <option value={txfreteDistante}
                onClick={() => setPrecocomfrete(Number(precoTotal) + Number(txfreteDistante))}
                >Entrega Bairros Distantes + R$:{txfreteDistante}</option>
              )}
            </select>
              
            <select type="text"  name="frete"  onChange={onChange} className='borda1 cont-circ15 h5'>
              <option value={0}>Selecione o frete</option>
              {optionInput}              
            </select>
            
            <input list="datalistOptions" className='borda1 cont-circ15 h5' style={{ color: 'black' }}/>
            <datalist id="datalistOptions" name="frete" onChange={onChange} className='c1'>
              {optionInput}
            </datalist>
            */}
           
            <Select options={options} onChange={handleSelectChange} className='z1' />
          
            

            <div className='b16 h20 w100 cont-circ8 column between ma10b0' >
              <div className='w100 h30 column center '>
                <div className="w90 bbot1 h5 row between">
                  <label className='w10 cell-texto-p'> Qnt.</label>
                  <label className='w50 cell-texto-p'>Item</label>
                  <label className='w20 cell-texto-p'>V.Unit</label>
                  <label className='w20 cell-texto-p'>Total</label>
                </div>
                
                {final}
              </div>
            </div>
           
            {/*<h5 className=''>Total R$: {total.toFixed(2)}</h5> */}
            <div className='row end'>
            <h5 className=''>Total R$: {parseFloat(precocomfrete).toFixed(2)}</h5>
            </div>
            <div className='w100 column'>
                {precocomfrete?
                <button className='cont-circ15 h5 w100' style={{backgroundColor: TenancyColor().backgroundColor}} onClick={() => nextNomeClienteFrete()}>Finalizar compra</button>
                :
                <button className='cont-circ15 h5 w100 b3 c1'onClick={() => alert('Ops, Selecione a forma de entrega.')}>Selecione a forma de entrega!</button>
                }
              <label className='b3 w100 column center h5 ma10b0 pp cont-circ15' onClick={() => { localStorage.removeItem("items"); rec(); }}>Limpar</label>
            </div>
          </div> 
          :
          <div className='w100 h80 column center'>
            <div className="b16 h20 w100 cont-circ8 ma10b0 column center">
            Sem itens
            </div>
          </div>
          }
        </div>
        <label className='fs08em ma10b0 w100 cen'>{tenancylocal} - Delivery</label> 
        <label>chav pix:</label>
      </section>
    </div>
  )
}

